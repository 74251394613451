import { useForm, SubmitHandler } from "react-hook-form";
import { Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { updateUserPassword } from "../../User/usersSlice";

type Inputs = {
    password: string
};

type Props = {
    user: User
}

export default function UserForm(props: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const dispatch = useDispatch<any>();

    const onSubmit: SubmitHandler<Inputs> = data => {
        const new_password = data.password;
        dispatch(updateUserPassword({id: props.user.id, password: new_password}));
    }

    const load_state = useSelector((state: any) => state.users.all_users.find((user: User) => user.id === props.user.id).password_change_load_state);

    let button = null;
    if (load_state?.state === 'error') {
        button = <Button className="float-end" variant="danger" disabled={true} type="submit">Došlo k chybě</Button>;
    } else if(load_state?.state === 'loading') {
        button = <Button className="float-end" variant="success" disabled={true} type="submit"><Spinner animation="border" role="status" size="sm"><span className="visually-hidden">Loading...</span></Spinner></Button>;
    } else {
        button = <Button className="float-end" variant="success" type="submit">Upravit heslo</Button>;
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nové heslo <span className="text-danger">*</span></Form.Label>
                        <InputGroup>
                            <Form.Control type="text" {...register("password", {required: "Heslo je nutné vyplnit", maxLength: 2048, minLength: 10})} isInvalid={!!errors.password} />
                            {button}
                        </InputGroup>
                        <Form.Text className={errors.password ? "text-danger" : "text-success"}>{errors.password?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}