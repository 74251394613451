const sale_states = {
    'CLOSED': {name: 'Uzavřeno bez obchodu', color: '#7e511d'},
    'CLOSED_NO_PASS': {name: 'Neschváleno', color: 'black'},
    'CLOSED_NO_SIGNATURE': {name: 'Schváleno, ale nepodepsáno', color: 'grey'},
    'CONFIRMED': {name: 'Jsou schváleni', color: '#8FBC8F'},
    'PHONE': {name: 'Schválit', color: 'blue'},
    'RESERVE': {name: 'Dovoz', color: 'purple'},
    'RETURNED': {name: 'Ke schválení', color: 'yellow'},
    'SENT': {name: 'Posláno na schválení', color: 'orange'},
    'SIGNED': {name: 'odepsáno', color: 'green'},
    'STOPPED': {name: 'Přestali komunikovat', color: '#ADD8E6'},
    'WAITING': {name: 'Čekáme na podklady', color: 'red'},
    'LEGACY': {name: 'Archiv', color: 'grey'}
};

export default sale_states;