
import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

interface Props {
    error?: boolean,
    valid_until?: Date
}

export default function LogoutTimer(props: Props) {
    const current_time = (new Date).getTime();
    const [pivot_time, setPivotTime] = useState(current_time);

    useEffect(() => {
        setPivotTime(current_time);
    }, [current_time]);

    useEffect(() => {
        setInterval(() => {
            setPivotTime((new Date).getTime());
        }, 1000)
    })

    let minutes, seconds;
    let css_class : string = '';
    if (props.valid_until) {
        const diff = differenceInSeconds(props.valid_until, new Date(pivot_time));
        minutes = Math.floor(diff / 60);
        seconds = diff % 60;
        css_class = 'text-white';
    }

    if (props.error) {
        css_class = 'text-danger';
    }

    let session_time_left;
    if (props.error) {
        session_time_left = 'Chyba';
    } else if (minutes === undefined) {
        session_time_left = '';
    } else {
        session_time_left = minutes?.toString().padStart(2, '0') + ":" + seconds?.toString().padStart(2, '0');
    }
    return <span className={css_class}>{session_time_left}</span>;
}