import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Alert, Spinner, Form, InputGroup } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteLead, fetchLeads } from '../../Lead/leadsSlice';
import { createFlashMessageWithDispatch } from '../../FlashMessages/utils';
import { fetchCustomers } from '../../Customer/customersSlice';

export default function LeadDetail() {

    const { id } = useParams();
    let lead = useSelector((state: any) => state.leads.all_leads.find((e: Lead) => e.id == id));
    lead = lead ? lead : {} as Lead;

    const all_customers = useSelector((state: any) => state.customers.all_customers);
    const customers_load_state = useSelector((state: any) => state.customers.load_state);

    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const handleBack = useCallback(() => navigate("/dashboard"), []);

    const load_state = useSelector((state: any) => state.leads.load_state);

    if (load_state.state === 'not_loaded') {
        dispatch(fetchLeads());
        return <div></div>;
    }

    if (customers_load_state.state === 'not_loaded') {
        dispatch(fetchCustomers());
        return <div></div>;
    }
    
    else if(load_state.state === 'loading' || customers_load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error' || customers_load_state.error === 'error') {
        return <Alert variant="danger">{load_state.error_message ? load_state.error_message : customers_load_state.error_message}</Alert>
    }

    const handleDelete = (id: number) => {
        dispatch(deleteLead(id));
        createFlashMessageWithDispatch(dispatch, 'success', 'Lead byl smazán');
        navigate("/");
    }

    const details : any = [];
    for (let key in lead.data) {
        details.push(<div key={key}>&nbsp;&nbsp;{key}: {lead.data[key]}</div>);
    }

    const customer_section = lead.customer_id ? <div>
        Pro tento lead byl založen zákazník: <a href="#!" onClick={() => navigate('/customer/' + lead.customer_id + '/' + lead.id)}>
            {all_customers.find((customer: Customer) => customer.id === lead.customer_id).name}
        </a>
    </div> : <Button onClick={() => navigate('/customer/new/' + lead.id)}>Založit zákazníka</Button>;

    return <div>
        <div className="float-end">
            <Button variant="warning" onClick={handleBack}>Zpět</Button>
        </div>

        <h1>{lead.name}</h1>

        <strong>ID leadu:</strong> {lead.lead_id}<br />
        <strong>Typ leadu:</strong> {lead.type}<br />
        <strong>E-mail:</strong> {lead.email}<br />
        <strong>Telefon:</strong> {lead.phone}<br />
        <strong>ID auta:</strong> {lead.car_id}<br />
        <strong>Název auta:</strong> {lead.car_name}<br />
        <strong>Ostatní parametry:</strong><br />
        {details}

        <br />

        <Button variant="danger" onClick={() => {
            if(window.confirm("Opravdu chcete lead smazat? Tato operace je NEVRATNÁ!")) {
                handleDelete(lead.id)
            }
        }}>Smazat lead</Button>

        <br /><br />

        {customer_section}
        &nbsp;&nbsp;
        <Button onClick={() => navigate('/sale/from-lead/' + lead.id)}>Založit obchod</Button>
    </div>;
};