import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { createTask, updateTask, deleteTask } from "../../Calendar/calendarSlice";
import { close } from "../../Calendar/tasksModalSlice";
import UserPicker from "../Common/UserPicker";
import DatePicker from "react-datepicker";
import { useEffect } from "react";

type Inputs = {
    id?: number | string,
    date: Date,
    title: string,
    description: string,
    owner_id: number
}

export default function TaskModal() {
    const modal_open = useSelector((state: any) => state.tasksModal.modal_open);
    const current_task = useSelector((state: any) => state.tasksModal.current_task);
    const role = useSelector((state: any) => state.auth.profile?.role);
    const dispatch = useDispatch<any>();

    const { control, register, handleSubmit, formState: { errors }, reset } = useForm<Inputs>({
        defaultValues: {
            date: current_task?.date,
            title: current_task?.title,
            description: current_task?.description
        }
    });

    useEffect(() => reset(current_task ? current_task : {} as Task), [reset, current_task]);

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        data.owner_id = parseInt(data.owner_id as any);

        if (data.id) {
            dispatch(updateTask(data));
        } else {
            dispatch(createTask(data));
        }

        dispatch(close());
    }

    const onDelete = (id: any) => {
        dispatch(deleteTask(id));
        dispatch(close());
    }

    return <Modal show={modal_open} onHide={() => dispatch(close())}>
        <Modal.Header closeButton>
            <Modal.Title>Volání</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Datum</Form.Label>
                            
                            <Controller control={control} name="date" render={({field}) => (
                                <DatePicker className={(!!errors.date ? "is-invalid " : "") + "form-control"} 
                                    onChange={(date) => field.onChange(date)} selected={field.value} dateFormat="d.M.yyyy" />
                            )} />
                            
                            <Form.Text className={errors.date ? "text-danger" : "text-success"}>{errors.date?.message}&nbsp;</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Titulek <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" {...register("title", {required: "Titulek je nutné vyplnit"})} isInvalid={!!errors.title} />
                            <Form.Text className={errors.title ? "text-danger" : "text-success"}>{errors.title?.message}&nbsp;</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Popis</Form.Label>
                            <Form.Control type="textarea" {...register("description")} isInvalid={!!errors.description} />
                            <Form.Text className={errors.title ? "text-danger" : "text-success"}>{errors.description?.message}&nbsp;</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                {role === 'admin' ?
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Uživatel <span className="text-danger">*</span></Form.Label>
                            <Controller
                                name="owner_id"
                                render={({field}) => <UserPicker {...field} />}
                                control={control}
                                defaultValue={current_task?.owner_id}
                                rules={{required: true}} />
                            <Form.Text className={errors.title ? "text-danger" : "text-success"}>{errors.owner_id?.message}&nbsp;</Form.Text>
                        </Form.Group>
                    </Col>
                </Row> : null}
            </Form>
        </Modal.Body>
        <Modal.Footer>
            {current_task?.id ? <Button variant="danger" style={{marginRight: 'auto'}} onClick={() => window.confirm("Opravdu chcete smazat úkol?") ? onDelete(current_task?.id as number) : null}>Smazat</Button> : ''}
            <Button variant="secondary" onClick={() => dispatch(close())}>Zavřít</Button>
            <Button variant="primary" type="submit" onClick={handleSubmit((e) => onSubmit(e))}>Uložit</Button>
        </Modal.Footer>
    </Modal>
}