import { useForm, SubmitHandler } from "react-hook-form";
import { Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { updatePassword } from "../../Auth/authSlice";

type Inputs = {
    former_password: string,
    new_password: string
};

type Props = {
    user: User
}

export default function UserForm(props: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const dispatch = useDispatch<any>();

    const onSubmit: SubmitHandler<Inputs> = data => {
        const former_password = data.former_password;
        const new_password = data.new_password;
        dispatch(updatePassword({new_password: new_password, former_password: former_password}));
    }

    const load_state = useSelector((state: any) => state.auth.password_update_state);

    let button = null;
    if (load_state?.state === 'error') {
        button = <Button className="float-end" variant="danger" disabled={true} type="submit">Došlo k chybě</Button>;
    } else if(load_state?.state === 'loading') {
        button = <Button className="float-end" variant="success" disabled={true} type="submit"><Spinner animation="border" role="status" size="sm"><span className="visually-hidden">Loading...</span></Spinner></Button>;
    } else {
        button = <Button className="float-end" variant="success" type="submit">Upravit heslo</Button>;
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>Původní heslo <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="password" {...register("former_password", {required: "Heslo je nutné vyplnit", maxLength: 2048, minLength: 10})} isInvalid={!!errors.former_password} />
                        <Form.Text className={errors.former_password ? "text-danger" : "text-success"}>{errors.former_password?.message}&nbsp;</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Nové heslo <span className="text-danger">*</span></Form.Label>
                        <InputGroup>
                            <Form.Control type="password" {...register("new_password", {required: "Heslo je nutné vyplnit", maxLength: 2048, minLength: 10})} isInvalid={!!errors.new_password} />
                            {button}
                        </InputGroup>
                        <Form.Text className={errors.new_password ? "text-danger" : "text-success"}>{errors.new_password?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}