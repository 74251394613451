import SaleForm from './SaleForm';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Attachments from '../Common/Attachments';
import { Button, Alert, Spinner, Table } from 'react-bootstrap';
import CallLog from '../Common/CallLog';
import { useParams, useNavigate } from 'react-router-dom';
import { updateSale, deleteSale, createSale, loadSaleCallLogs, fetchSales } from '../../Sale/salesSlice';
import { fetchCustomers } from '../../Customer/customersSlice';
import { createFlashMessageWithDispatch } from '../../FlashMessages/utils';
import { format } from 'date-fns';
import LegacySale from './LegacySale';
import { fetchLeads } from '../../Lead/leadsSlice';

export default function SaleDetail() {
    const load_state = useSelector((state: any) => state.sales.load_state);
    const dispatch = useDispatch<any>();
    const { id, customer_id, lead_id } = useParams();
    const navigate = useNavigate();
    let sale = useSelector((state: any) => state.sales.all_sales.find((a: Sale) => a.id == id));
    let lead = useSelector((state: any) => state.leads.all_leads.find((e: Lead) => e.id == lead_id));
    let lead_load_state = useSelector((state: any) => state.leads.load_state);

    if (lead_load_state.state === 'not_loaded') {
        dispatch(fetchLeads());
    }

    const all_customers = useSelector((state: any) => state.customers.all_customers);
    const customers_load_state = useSelector((state: any) => state.customers.load_state);

    const handleBack = useCallback(() => lead ? navigate("/lead/" + lead.id) : navigate(customer_id ? `/customer/${customer_id}` : `/sales`), []);

    if (customers_load_state.state === 'not_loaded') {
        dispatch(fetchCustomers());
        return <div></div>;
    }

    if (load_state.state === 'not_loaded') {
        dispatch(fetchSales());
        return <div></div>;
    } else if(load_state.state === 'loading' || customers_load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error' || customers_load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    if (lead) {
        sale = lead.type == 'DovozKarlin' ? {import: {} as Import} as Sale : {leasing: {} as Leasing} as Sale;
    } else {
        sale = sale ? sale : {import: {} as Import} as Sale;
    }


    if (sale.id && !sale.call_logs_loaded) {
        dispatch(loadSaleCallLogs(sale.id))
    }

    const handleDelete = (id: number) => {
        dispatch(deleteSale(id));
        createFlashMessageWithDispatch(dispatch, 'success', 'Obchod byl smazán');
        navigate(customer_id ? `/customer/${customer_id}` : `/sales`);
    }

    const handleSaleFormSuccess = (sale: Sale) => {
        if (sale.id) {
            createFlashMessageWithDispatch(dispatch, 'success', 'Obchod byl upraven');
            dispatch(updateSale(sale));
        } else {
            createFlashMessageWithDispatch(dispatch, 'success', 'Obchod byl založen');
            dispatch(createSale(sale));
        }
        navigate(customer_id ? `/customer/${customer_id}` : `/sales`);
    }

    const additional_components = sale.id && !sale.legacy ? <div>
        <Attachments parent={sale} attachments={sale.attachments} readOnly={sale.legacy} />

        <div className="clearfix"><br /><br /></div>

        <CallLog parent={sale} />
    </div> : '';

    let detail_component;
    if (sale.legacy) {
        detail_component = <LegacySale sale={sale} />
    } else {
        detail_component = <SaleForm sale={sale} lead={lead} all_customers={all_customers} customer_id={customer_id ? parseInt(customer_id) : undefined} onDeleteClick={handleDelete} onSaleFormSuccess={handleSaleFormSuccess} />
    }

    const audit_info = sale.id ? <p className="text-end"><small><em>
        Založil {sale.user_created} {format(sale.date_created, 'd. M. yyyy HH:mm')}
        {(sale.date_modified ? (", Upravil " + sale.user_modified + " " + format(sale.date_modified, 'd. M. yyyy HH:mm')) : null)}
    </em></small></p> : null;

    return <div>
        <div className="float-end">
            <Button variant="warning" onClick={handleBack}>Zpět</Button>
        </div>

        <h1>Obchod {sale.subject}</h1>

        {audit_info}

        <div className="clearfix"></div>

        {detail_component}

        <div className="clearfix"></div>

        {additional_components}
    </div>;
};