import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchCustomers as apiFetchCustomers, updateCustomer as apiUpdateCustomer, createCustomer as apiCreateCustomer, deleteCustomer as apiDeleteCustomer } from './api';
import { fetchCallLogs as apiFetchCallLogs, updateCallLog as apiUpdateCallLog, createCallLog as apiCreateCallLog, deleteCallLog as apiDeleteCallLog } from '../Common/callLogsApi';
import { uploadAttachment as apiUploadAttachment, deleteAttachment as apiDeleteAttachment } from '../Common/attachmentsApi';
import { fetchLeads, setCustomer } from '../Lead/leadsSlice';


export const fetchCustomers = createAsyncThunk(
    'customers/fetchCustomers',
    async (_, { getState, dispatch }) : Promise<Customer[]> => {
        const state : any = getState();
        const customers = await apiFetchCustomers(dispatch, state.auth.api_token);
        return customers;
    }
)

export const updateCustomer = createAsyncThunk(
    'customers/updateCustomer',
    async (customer: Customer, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiUpdateCustomer(dispatch, state.auth.api_token, customer);
        return customer;
    }
)

export const createCustomer = createAsyncThunk(
    'customers/createCustomer',
    async (customer: Customer, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const id = await apiCreateCustomer(dispatch, state.auth.api_token, customer);
        customer.id = id as number;

        if (customer.lead_id) {
            dispatch(setCustomer({lead_id: customer.lead_id, related_obj_id: customer.id}));
        }

        return customer;
    }
)

export const deleteCustomer = createAsyncThunk(
    'customers/deleteCustomer',
    async (id: number, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteCustomer(dispatch, state.auth.api_token, id);
        dispatch(fetchLeads());
        return id;
    }
)

export const loadCustomerCallLogs = createAsyncThunk(
    'customers/loadCallLogs',
    async (id: number, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const call_logs = await apiFetchCallLogs(dispatch, state.auth.api_token, 'customer', id);
        return { customer_id: id, call_logs: call_logs};
    }
)

export const updateCallLog = createAsyncThunk(
    'customers/updateCallLog',
    async (props : any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiUpdateCallLog(dispatch, state.auth.api_token, props.call_log);
        return { customer_id: props.customer_id, call_log: props.call_log};
    }
)

export const createCallLog = createAsyncThunk(
    'customers/createCallLog',
    async (props : any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const id = await apiCreateCallLog(dispatch, state.auth.api_token, 'customer', props.customer_id, props.call_log);
        props.call_log.id = id as number;
        return { customer_id: props.customer_id, call_log: props.call_log};
    }
)

export const deleteCallLog = createAsyncThunk(
    'customers/deleteCallLog',
    async (props: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteCallLog(dispatch, state.auth.api_token, props.id);
        return { customer_id: props.customer_id, id: props.id };
    }
)

export const uploadAttachment = createAsyncThunk(
    'customers/uploadAttachment',
    async (props: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const attachments = await apiUploadAttachment(dispatch, state.auth.api_token, 'customer', props.customer_id, props.form_data);
        return { customer_id: props.customer_id, attachments: attachments };
    }
)

export const deleteAttachment = createAsyncThunk(
    'customers/deleteAttachment',
    async (props: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteAttachment(dispatch, state.auth.api_token, 'customer', props.customer_id, props.id);
        return { customer_id: props.customer_id, id: props.id};
    }
)

const customersSlice = createSlice({
    name: "customers",
    initialState: {
        all_customers: [] as Customer[],
        load_state: {
            state: 'not_loaded'
        } as LoadState
    },
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(fetchCustomers.fulfilled, (state, action) => {
            state.all_customers = action.payload;
            state.load_state.state = 'loaded';
        });
        builder.addCase(fetchCustomers.pending, (state, action) => {
            state.load_state.state = 'loading';
        });
        builder.addCase(fetchCustomers.rejected, (state, action) => {
            state.load_state.state = 'error';
            state.load_state.error_message = action.payload as string;
        });
        builder.addCase(updateCustomer.fulfilled, (state, action) => {
            const index = state.all_customers.findIndex((e) => {
                return e.id === action.payload.id;
            });
            state.all_customers[index] = action.payload;
        });
        builder.addCase(createCustomer.fulfilled, (state, action) => {
            let customer = action.payload;
            state.all_customers.push(customer);
        });
        builder.addCase(deleteCustomer.fulfilled, (state, action) => {
            let id = action.payload;
            let index = state.all_customers.findIndex(a => a.id === id);
            state.all_customers.splice(index, 1);
        });
        builder.addCase(loadCustomerCallLogs.fulfilled, (state, action) => {
            const call_logs = action.payload.call_logs;
            const customer_id = action.payload.customer_id;
            const index = state.all_customers.findIndex(a => a.id === customer_id);
            state.all_customers[index].call_logs = call_logs;
            state.all_customers[index].call_logs_loaded = true;
        });
        builder.addCase(createCallLog.fulfilled, (state, action) => {
            const call_log = action.payload.call_log;
            const customer_id = action.payload.customer_id;
            const index = state.all_customers.findIndex(a => a.id === customer_id);

            if (!state.all_customers[index].call_logs_loaded) {
                throw `Cannot update call logs for customer ${customer_id} - call logs not loaded`;
            }

            state.all_customers[index].call_logs?.push(call_log);
        });
        builder.addCase(updateCallLog.fulfilled, (state, action) => {
            let id = action.payload.customer_id;
            let call_log = action.payload.call_log;
            let index = state.all_customers.findIndex(a => a.id === id);
        
            if (!state.all_customers[index].call_logs_loaded) {
                throw `Cannot update call logs for customer ${id} - call logs not loaded`;
            }
        
            const call_logs = state.all_customers[index].call_logs;
        
            if (call_logs) {
                const call_log_index = call_logs.findIndex(a => a.id === call_log.id);
        
                if (call_log_index !== -1) {
                    call_logs[call_log_index] = call_log;
                }
            }
        });
        builder.addCase(deleteCallLog.fulfilled, (state, action) => {
            let id = action.payload.customer_id;
            let call_log_id = action.payload.id;
            let index = state.all_customers.findIndex(a => a.id === id);

            if (!state.all_customers[index].call_logs_loaded) {
                throw `Cannot update call logs for customer ${id} - call logs not loaded`;
            }

            const call_logs = state.all_customers[index].call_logs;

            if (call_logs) {
                const call_log_index = call_logs.findIndex(a => a.id === call_log_id);

                if (call_log_index !== -1) {
                    call_logs.splice(index, 1);
                }
            }

            state.all_customers[index].call_logs = call_logs;
        });
        builder.addCase(uploadAttachment.fulfilled, (state, action) => {
            let id = action.payload.customer_id;
            let index = state.all_customers.findIndex(a => a.id === id);

            const attachments = action.payload.attachments;

            for (const attachment of attachments) {
                state.all_customers[index].attachments?.push(attachment);
            }
        });
        builder.addCase(deleteAttachment.fulfilled, (state, action) => {
            let customer_id = action.payload.customer_id;
            let id = action.payload.id;
            let customer_index = state.all_customers.findIndex(a => a.id === customer_id);
            let attachment_index = state.all_customers[customer_index].attachments?.findIndex(a => a.id === id);

            if (attachment_index) {
                state.all_customers[customer_index].attachments?.splice(attachment_index, 1);
            }
        });
    }
});

export default customersSlice.reducer