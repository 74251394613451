import { format } from "date-fns";
import { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Calendar from "../Calendar/Calendar";
import LeadList from "../Lead/LeadList";
import cs from "date-fns/locale/cs";

export default function Dashboard(props: any) {
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());

    const date = new Date(year, month);

    return <div>
        <Row style={{marginBottom: '1rem'}}>
            <Col>
                <LeadList />
            </Col>
        </Row>
        <Row>
            <Col>
                <Button variant="secondary" className="float-start" onClick={() => setMonth(month - 1)}>&lt;</Button>
                <Button variant="secondary" className="float-end" onClick={() => setMonth(month + 1)}>&gt;</Button>
                <h4 onClick={() => {
                    setMonth(new Date().getMonth());
                    setYear(new Date().getFullYear());
                }} style={{cursor: 'pointer'}} className="text-center">{format(date, 'LLLL yyyy', {locale: cs})}</h4>
                <br /><br />
                <Calendar year={year} month={month} />
            </Col>
        </Row>
    </div>
}