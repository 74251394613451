import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchLeads as apiFetchLeads, deleteLead as apiDeleteLead, setCustomer as apiSetCustomer, setSale as apiSetSale } from './api';

interface LeadRelationParams {
    related_obj_id: number,
    lead_id: number
}

export const fetchLeads = createAsyncThunk(
    'leads/fetchLeads',
    async (_, { getState, dispatch }) : Promise<Lead[]> => {
        const state : any = getState();
        const leads = await apiFetchLeads(dispatch, state.auth.api_token);
        return leads;
    }
)

export const deleteLead = createAsyncThunk(
    'leads/deleteLead',
    async (id: number, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteLead(dispatch, state.auth.api_token, id);
        return id;
    }
)

export const setCustomer = createAsyncThunk(
    'leads/setCustomer',
    async (params: LeadRelationParams, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiSetCustomer(dispatch, state.auth.api_token, params.lead_id, params.related_obj_id);
        dispatch(fetchLeads());
    }
)

export const setSale = createAsyncThunk(
    'leads/setCustomer',
    async (params: LeadRelationParams, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiSetSale(dispatch, state.auth.api_token, params.lead_id, params.related_obj_id);
        dispatch(fetchLeads());
    }
)

const leadsSlice = createSlice({
    name: "leads",
    initialState: {
        all_leads: [] as Lead[],
        load_state: {
            state: 'not_loaded'
        } as LoadState
    },
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(fetchLeads.fulfilled, (state, action) => {
            state.all_leads = action.payload;
            state.load_state.state = 'loaded';
        });
        builder.addCase(fetchLeads.pending, (state, action) => {
            state.load_state.state = 'loading';
        });
        builder.addCase(fetchLeads.rejected, (state, action) => {
            state.load_state.state = 'error';
            state.load_state.error_message = action.payload as string;
        });
        builder.addCase(deleteLead.fulfilled, (state, action) => {
            let id = action.payload;
            let index = state.all_leads.findIndex(a => a.id === id);
            state.all_leads.splice(index, 1);
        });
    }
});

export default leadsSlice.reducer