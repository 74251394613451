import { FormEvent, useState } from 'react';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import states from '../../Common/saleStates';
import { numfor } from '../../Common/formats';

type Inputs = {
    customer_id: number,
    lead_id?: number,
    state: keyof typeof states,
    date: Date,
    subject: string,
    leasing_active: boolean,
    sale_active: boolean,
    import_active: boolean,
    leasing_supplier: string,
    leasing_conditions: string,
    leasing_sent_to: string,
    leasing_signed_with: string[],
    leasing_tasks: string,
    leasing_notes: string,
    leasing_date_signed: Date,
    leasing_info_signed: string,
    leasing_pd_leasing: number,
    leasing_pd_insurance: number,
    leasing_pd_other: number,
    leasing_insurance: string,
    leasing_expenses_signature: number,
    leasing_expenses_bonus: number,
    leasing_expenses_other: number,
    leasing_expenses_notes: string,
    leasing_notes2: string,
    sale_price_purchase: number,
    sale_price_sale: number,
    sale_notes_tasks: string,
    sale_pd_leasing: number,
    sale_pd_cash: number,
    sale_pd_insurance: number,
    sale_pd_defend: number,
    sale_insurance_info: string,
    sale_expenses_washing: number,
    sale_expenses_service: number,
    sale_expenses_sf: number,
    sale_expenses_bonus: number,
    sale_expenses_other: number,
    sale_expenses_notes: string,
    sale_signature_info: string,
    sale_notes: string,
    import_vat_included: boolean,
    import_vat_amount: number,
    import_vat_returned: boolean,
    import_price_subject_own: number,
    import_price_subject_client: number,
    import_rate_own: number,
    import_rate_client: number,
    import_payment_type: string,
    import_tasks: string,
    import_signature_date: Date,
    import_signature_info: string,
    import_notes: string,
    import_price_transport: number,
    import_price_washing: number,
    import_price_stk: number,
    import_price_papers: number,
    import_price_defend_own: number,
    import_price_defend_client: number,
    import_price_service: number,
    import_price_insurance_note: string,
    import_price_insurance: number,
    import_price_control: number,
    import_price_other: number,
    import_expenses_notes: string,
    import_notes2: string
};

interface Props {
    sale: Sale,
    lead?: Lead,
    customer_id?: number,
    all_customers: Customer[],
    onDeleteClick: Function,
    onSaleFormSuccess: Function
}

export default function SaleForm(props: Props) {

    const default_blocks = {
        leasing: !!props.sale.leasing,
        import: !!props.sale.import,
        sale: !!props.sale.sale
    };

    const calculateBlockSize = (blocks: typeof default_blocks) => 12 / Object.values(blocks).reduce((a: number, item: boolean) => a + (item ? 1 : 0), 0);

    const [blocks, setBlocks] = useState(default_blocks);
    const [block_size, setBlockSize] = useState(calculateBlockSize(default_blocks));

    const defaults_import: any = {};
    if (props.sale.import) {
        for (let key of Object.keys(props.sale.import)) {
            defaults_import[`import_${key}` as any] = props.sale.import[key as keyof Import];
        }
    }

    const defaults_sale: any = {};
    if (props.sale.sale) {
        for (let key of Object.keys(props.sale.sale)) {
            defaults_sale[`sale_${key}` as any] = props.sale.sale[key as keyof InnerSale];
        }
    }

    const defaults_leasing: any = {};
    if (props.sale.leasing) {
        for (let key of Object.keys(props.sale.leasing)) {
            defaults_leasing[`leasing_${key}` as any] = props.sale.leasing[key as keyof Leasing];
        }
    }

    const defaults = {...props.sale, ...defaults_sale, ...defaults_import, ...defaults_leasing} as Inputs;

    if (props.customer_id) {
        defaults.customer_id = props.customer_id;
    }

    if (props.lead) {
        defaults.lead_id = props.lead.id;
        defaults.subject = props.lead.car_name;
    }

    const { control, register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({defaultValues: defaults});
    const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
        const sale = {} as any;
        const _import = {} as any;
        const _leasing = {} as any;
        const _sale = {} as any;

        if (props.sale) {
            sale.id = props.sale.id;
        }

        Object.keys(data).forEach((key: string) => {
            const value = data[key as keyof Inputs] as any;
            if (key.startsWith('leasing_')) {
                if (!blocks.leasing) return;
                _leasing[key.substring(8) as keyof Leasing] = value;
            } else if (key.startsWith('import_')) {
                if (!blocks.import) return;
                _import[key.substring(7) as keyof Import] = value;
            } else if (key.startsWith('sale_')) {
                if (!blocks.sale) return;
                _sale[key.substring(5) as keyof InnerSale] = value;
            } else {
                sale[key as keyof Sale] = value;
            }
        });

        if (blocks.import) {
            sale.import = _import;
        } else {
            sale.import = undefined;
        }

        if (blocks.leasing) {
            sale.leasing = _leasing;
        } else {
            sale.leasing = undefined;
        }

        if (blocks.sale) {
            sale.sale = _sale;
        } else {
            sale.sale = undefined;
        }

        props.onSaleFormSuccess(sale);
    }

    const switchSaleBlocks = (e: FormEvent<HTMLInputElement>, block: string) => {
        let blocks_copy = {...blocks};
        blocks_copy[block as keyof typeof blocks] = e.currentTarget.checked;
        setBlocks(blocks_copy);
        setBlockSize(calculateBlockSize(blocks_copy));
    }

    const import_vat_included = watch("import_vat_included");

    let leasing = null;
    if (blocks.leasing) {
        const leasing_pd_loan: number = watch("leasing_pd_leasing") || 0;
        const leasing_pd_insurance: number = watch("leasing_pd_insurance") || 0;
        const leasing_pd_other: number = watch("leasing_pd_other") || 0;
        const leasing_pd_total: number = +leasing_pd_loan + +leasing_pd_insurance + +leasing_pd_other;
        
        const leasing_expenses_signature: number = watch("leasing_expenses_signature") || 0;
        const leasing_expenses_bonus: number = watch("leasing_expenses_bonus") || 0;
        const leasing_expenses_other: number = watch("leasing_expenses_other") || 0;
        const leasing_expenses_total: number = +leasing_expenses_signature + +leasing_expenses_bonus + +leasing_expenses_other;

        const leasing_profit: number = leasing_pd_total - leasing_expenses_total;
        leasing = <Col md={block_size}>
            <h3>Úvěr</h3>

            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Dodavatel</Form.Label>
                        <Form.Control type="text" {...register("leasing_supplier")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Podmínky financování</Form.Label>
                        <Form.Control type="text" {...register("leasing_conditions")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Posláno na</Form.Label>
                        <Form.Control type="text" {...register("leasing_sent_to")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Podepsáno</Form.Label>
                        <Form.Select {...register("leasing_signed_with")} multiple={true}>
                            <option>ML</option>
                            <option>MA</option>
                            <option>UNI</option>
                            <option>ESSOX</option>
                            <option>HC</option>
                            <option>ČSOB</option>
                            <option>SAUTO</option>
                            <option>COFID</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Úkoly</Form.Label>
                        <Form.Control type="text" {...register("leasing_tasks")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Poznámky</Form.Label>
                        <Form.Control type="text" {...register("leasing_notes")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Datum podpisu</Form.Label>
                        <Controller control={control} name="leasing_date_signed" render={({field}) => (
                            <DatePicker className={(!!errors.leasing_date_signed ? "is-invalid " : "") + "form-control"} 
                                onChange={(leasing_date_signed) => field.onChange(leasing_date_signed)} selected={field.value} dateFormat="d.M.yyyy" />
                        )} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Info podpis</Form.Label>
                        <Form.Control type="text" {...register("leasing_info_signed")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>PD</h3>

                    <Table>
                        <thead>
                            <tr>
                                <th>Úvěr</th>
                                <th>Z pojistky</th>
                                <th>Jiné</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Form.Control type="number" {...register("leasing_pd_leasing")} /></td>
                                <td><Form.Control type="number" {...register("leasing_pd_insurance")} /></td>
                                <td><Form.Control type="number" {...register("leasing_pd_other")} /></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}></td>
                                <td><Form.Control disabled value={numfor(leasing_pd_total)} /></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Pojištění</Form.Label>
                        <Form.Control type="text" {...register("leasing_insurance")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Náklady</h3>

                    <Table>
                        <thead>
                            <tr>
                                <th>Cesta podpis</th>
                                <th>Bonus</th>
                                <th>Jiné</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Form.Control type="number" {...register("leasing_expenses_signature")} /></td>
                                <td><Form.Control type="number" {...register("leasing_expenses_bonus")} /></td>
                                <td><Form.Control type="number" {...register("leasing_expenses_other")} /></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}></td>
                                <td><Form.Control disabled value={numfor(leasing_expenses_total)} /></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Náklady poznámky</Form.Label>
                        <Form.Control type="text" {...register("leasing_expenses_notes")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Zisk</Form.Label>
                        <Form.Control type="text" disabled value={numfor(leasing_profit)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Poznámky</Form.Label>
                        <Form.Control type="text" {...register("leasing_notes2")} />
                    </Form.Group>
                </Col>
            </Row>
        </Col>
    }

    let _import = null;
    if (blocks.import) {
        const import_price_subject_own = watch("import_price_subject_own") || 0;
        const import_price_subject_client = watch("import_price_subject_client") || 0;
        const import_price_profit = +import_price_subject_client - +import_price_subject_own;

        const import_rate_own = watch("import_rate_own") || 0;
        const import_rate_client = watch("import_rate_client") || 0;
        const import_rate_profit = +import_rate_client - +import_rate_own;

        const import_total_price_subject_own = +import_price_subject_own * +import_rate_own;
        const import_total_price_subject_client = +import_price_subject_client * +import_rate_client;
        const import_total_price_subject_profit = +import_total_price_subject_client - +import_total_price_subject_own;

        const import_price_transport = watch("import_price_transport") || 0;
        const import_price_washing = watch("import_price_washing") || 0;
        const import_price_stk = watch("import_price_stk") || 0;
        const import_price_papers = watch("import_price_papers") || 0;
        const import_price_service = watch("import_price_service") || 0;
        const import_price_control = watch("import_price_control") || 0;
        const import_price_others = watch("import_price_other") || 0;

        const import_price_defend_own = watch("import_price_defend_own") || 0;
        const import_price_defend_client = watch("import_price_defend_client") || 0;
        const import_price_defend_profit = +import_price_defend_client - +import_price_defend_own;

        const import_price_insurance_profit = watch("import_price_insurance") || 0;

        const import_expenses = +import_price_transport + +import_price_washing + +import_price_stk + +import_price_papers + +import_price_service + +import_price_control + +import_price_others;
        const import_profit = +import_total_price_subject_profit + +import_price_defend_profit - +import_expenses + +import_price_insurance_profit;

        _import = <Col md={block_size}>
            <h3>Dovoz</h3>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>DPH</Form.Label>
                        <Form.Check {...register("import_vat_included")} />
                    </Form.Group>
                </Col>
            </Row>

            {import_vat_included ?
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Částka DPH</Form.Label>
                        <Form.Control type="number" {...register("import_vat_amount")} />
                    </Form.Group>
                </Col>
            </Row> : ''}

            {import_vat_included ?
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>DPH vráceno</Form.Label>
                        <Form.Check {...register("import_vat_returned")} />
                    </Form.Group>
                </Col>
            </Row> : ''}
            
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>Položka</th>
                                <th>DK</th>
                                <th>Klient</th>
                                <th>Zisk</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Cena EUR</td>
                                <td><Form.Control type="number" {...register("import_price_subject_own")} /></td>
                                <td><Form.Control type="number" {...register("import_price_subject_client")} /></td>
                                <td><Form.Control disabled value={numfor(import_price_profit)} /></td>
                            </tr>
                            <tr>
                                <td>Kurz</td>
                                <td><Form.Control type="number" {...register("import_rate_own")} /></td>
                                <td><Form.Control type="number" {...register("import_rate_client")} /></td>
                                <td><Form.Control disabled value={numfor(import_rate_profit)} /></td>
                            </tr>
                            <tr>
                                <td>Celkem CZK</td>
                                <td><Form.Control disabled value={numfor(import_total_price_subject_own)} /></td>
                                <td><Form.Control disabled value={numfor(import_total_price_subject_client)} /></td>
                                <td><Form.Control disabled value={numfor(import_total_price_subject_profit)} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Jak klient platí</Form.Label>
                        <Form.Select {...register("import_payment_type")}>
                            <option value="loan">Úvěr</option>
                            <option value="cash">Hotovost</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Úkoly</Form.Label>
                        <Form.Control type="text" {...register("import_tasks")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Poznámky</Form.Label>
                        <Form.Control type="text" {...register("import_notes")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Datum podpisu</Form.Label>
                        <Controller control={control} name="import_signature_date" render={({field}) => (
                            <DatePicker className={(!!errors.import_signature_date ? "is-invalid " : "") + "form-control"} 
                                onChange={(import_signature_date) => field.onChange(import_signature_date)} selected={field.value} dateFormat="d.M.yyyy" />
                        )} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Info podpis</Form.Label>
                        <Form.Control type="text" {...register("import_signature_info")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5>Náklady</h5>
                    <Table>
                        <thead>
                            <tr>
                                <th>Položka</th>
                                <th>Cena</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Převoz</td>
                                <td><Form.Control type="number" {...register("import_price_transport")} /></td>
                            </tr>
                            <tr>
                                <td>Mytí</td>
                                <td><Form.Control type="number" {...register("import_price_washing")} /></td>
                            </tr>
                            <tr>
                                <td>STK</td>
                                <td><Form.Control type="number" {...register("import_price_stk")} /></td>
                            </tr>
                            <tr>
                                <td>Přepis</td>
                                <td><Form.Control type="number" {...register("import_price_papers")} /></td>
                            </tr>
                            <tr>
                                <td>Servis</td>
                                <td><Form.Control type="number" {...register("import_price_service")} /></td>
                            </tr>
                            <tr>
                                <td>Kontrola</td>
                                <td><Form.Control type="number" {...register("import_price_control")} /></td>
                            </tr>
                            <tr>
                                <td>Ostatní</td>
                                <td><Form.Control type="number" {...register("import_price_other")} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5>Defend</h5>
                    <Table>
                        <thead>
                            <tr>
                                <th>DK</th>
                                <th>Klient</th>
                                <th>Zisk</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Form.Control type="number" {...register("import_price_defend_own")} /></td>
                                <td><Form.Control type="number" {...register("import_price_defend_client")} /></td>
                                <td><Form.Control disabled value={numfor(import_price_defend_profit)} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5>Pojistka</h5>
                    <Table>
                        <thead>
                            <tr>
                                <th>Poznámka</th>
                                <th>Zisk</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Form.Control type="text" {...register("import_price_insurance_note")} /></td>
                                <td><Form.Control type="number" {...register("import_price_insurance")} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Náklady poznámky</Form.Label>
                        <Form.Control type="text" {...register("import_expenses_notes")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Náklady celkem</Form.Label>
                        <Form.Control type="text" disabled value={numfor(import_expenses)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>CELKEM čistý ZISK</Form.Label>
                        <Form.Control type="text" disabled value={numfor(import_profit)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Poznámky</Form.Label>
                        <Form.Control type="text" {...register("import_notes2")} />
                    </Form.Group>
                </Col>
            </Row>
        </Col>;
    }

    let sale = null;
    if (blocks.sale) {
        const sale_price_purchase = watch("sale_price_purchase") || 0;
        const sale_price_sale = watch("sale_price_sale") || 0;
        const sale_price_profit = +sale_price_sale - +sale_price_purchase;

        const sale_expenses_washing = watch("sale_expenses_washing") || 0;
        const sale_expenses_service = watch("sale_expenses_service") || 0;
        const sale_expenses_sf = watch("sale_expenses_sf") || 0;
        const sale_expenses_bonus = watch("sale_expenses_bonus") || 0;
        const sale_expenses_other = watch("sale_expenses_other") || 0;
        const sale_expenses_total = +sale_expenses_washing + +sale_expenses_service + +sale_expenses_sf + +sale_expenses_bonus + +sale_expenses_other;

        const sale_profit = +sale_price_profit - +sale_expenses_total;

        sale = <Col md={block_size}>
            <h3>Prodej</h3>
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>Nákup</th>
                                <th>Prodej</th>
                                <th>Zisk</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Form.Control type="number" {...register("sale_price_purchase")} /></td>
                                <td><Form.Control type="number" {...register("sale_price_sale")} /></td>
                                <td><Form.Control disabled value={numfor(sale_price_profit)} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Poznámky / úkoly</Form.Label>
                        <Form.Control type="text" {...register("sale_notes_tasks")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Informace k pojistce</Form.Label>
                        <Form.Control type="text" {...register("sale_insurance_info")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Náklady</h3>

                    <Table>
                        <thead>
                            <tr>
                                <th>Mytí</th>
                                <th>Servis</th>
                                <th>SF</th>
                                <th>Bonus</th>
                                <th>Jiné</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Form.Control type="number" {...register("sale_expenses_washing")} /></td>
                                <td><Form.Control type="number" {...register("sale_expenses_service")} /></td>
                                <td><Form.Control type="number" {...register("sale_expenses_sf")} /></td>
                                <td><Form.Control type="number" {...register("sale_expenses_bonus")} /></td>
                                <td><Form.Control type="number" {...register("sale_expenses_other")} /></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4}></td>
                                <td><Form.Control disabled value={numfor(sale_expenses_total)} /></td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Náklady poznámky</Form.Label>
                        <Form.Control type="text" {...register("sale_expenses_notes")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Zisk</Form.Label>
                        <Form.Control type="text" disabled value={numfor(sale_profit)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Info k podpisu</Form.Label>
                        <Form.Control type="text" {...register("sale_signature_info")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Poznámky</Form.Label>
                        <Form.Control type="text" {...register("sale_notes")} />
                    </Form.Group>
                </Col>
            </Row>
        </Col>
    }

    const customer_options = props.all_customers.map((customer) => <option key={customer.id} value={customer.id}>{customer.name}</option>);
    const customer_block = <Form.Group className="mb-3">
        <Form.Label>Zákazník</Form.Label>
        <Form.Select {...register("customer_id")}>
            {customer_options}
        </Form.Select>
    </Form.Group>;

    const states_options = Object.keys(states).map((code) => <option key={code} value={code}>{states[code as keyof typeof states].name}</option>);
    const state_block = <Form.Group className="mb3">
        <Form.Label>Stav</Form.Label>
        <Form.Select {...register("state")}>
            {states_options}
        </Form.Select>
    </Form.Group>;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>

            <input type="hidden" {...register('lead_id')} />

            <Row>
                <Col md={6}>
                    {customer_block}
                </Col>
                <Col md={6}>
                    {state_block}
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Datum <span className="text-danger">*</span></Form.Label>
                        <Controller control={control} rules={{required: "Datum je nutné vyplnit"}} name="date" render={({field}) => (
                            <DatePicker className={(!!errors.date ? "is-invalid " : "") + "form-control"} 
                                onChange={(date) => field.onChange(date)} selected={field.value} dateFormat="d.M.yyyy" />
                        )} />
                        <Form.Text className={errors.date ? "text-danger" : "text-success"}>{errors.date?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Předmět <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="text" {...register("subject", {required: "Předmět je nutné vyplnit"})} isInvalid={!!errors.subject} />
                        <Form.Text className={errors.subject ? "text-danger" : "text-success"}>{errors.subject?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className="mb-5">
                    <Form.Check inline type="switch" checked={blocks.import} label="Dovoz" {...register("import_active", { onChange: (e) => switchSaleBlocks(e, 'import')})} /> &nbsp;&nbsp;&nbsp;&nbsp;
                    <Form.Check inline type="switch" checked={blocks.leasing} label="Úvěr" {...register("leasing_active", { onChange: (e) => switchSaleBlocks(e, 'leasing')})} /> &nbsp;&nbsp;&nbsp;&nbsp;
                    <Form.Check inline type="switch" checked={blocks.sale} label="Prodej" {...register("sale_active", { onChange: (e) => switchSaleBlocks(e, 'sale')})} /> 
                </Col>
            </Row>

            <Row className="sale-detail-columns">
                {_import}
                {leasing}
                {sale}
            </Row>
        
            <Button className="float-end" variant="success" type="submit">Uložit</Button>
            {props.sale ? <Button onClick={() => {
                if(window.confirm("Opravdu chcete obchod smazat? Tato operace je NEVRATNÁ!")) {
                    props.onDeleteClick(props.sale.id);
                }
            }} variant="danger" type="button">Smazat</Button> : ''}
        </Form>
    );
}