import { performApiRequest } from '../Common/api'
import format from 'date-fns/format';

export const fetchTasks = async (dispatch: any, token: string) => {
    const response = await performApiRequest(dispatch, '/calendar/tasks', 'GET', token);
    const body = await response.json();
    for (const task of body) {
        task.date = task.date ? new Date(task.date) : null;
    }
    return body;
};

export const updateTask = async (dispatch: any, token: string, task: Task) => {
    const payload = {...task} as any;
    payload.date = payload.date ? format(payload.date, 'yyyy-MM-dd') : null;
    await performApiRequest(dispatch, '/calendar/tasks/' + task.id, 'PUT', token, payload);
};

export const createTask = async (dispatch: any, token: string, task: Task) : Promise<number> => {
    const payload = {...task} as any;
    payload.date = payload.date ? format(payload.date, 'yyyy-MM-dd') : null;
    const response = await performApiRequest(dispatch, '/calendar/tasks', 'POST', token, payload);
    const id = parseInt(response.headers.get('Id') as string);
    return id;
};

export const deleteTask = async (dispatch: any, token: string, id: number) => {
    await performApiRequest(dispatch, '/calendar/tasks/' + id, 'DELETE', token);
};