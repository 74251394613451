import { performApiRequest } from '../Common/api'

export const fetchSales = async (dispatch: any, token: string) => {
    const response = await performApiRequest(dispatch, '/sales', 'GET', token);
    const body = await response.json();
    for (const sale of body) {
        sale.date = new Date(sale.date);

        if (sale.leasing && sale.leasing.date_signed) {
            sale.leasing.date_signed = new Date(sale.leasing.date_signed);
        }

        if (sale.import && sale.import.signature_date) {
            sale.import.signature_date = new Date(sale.import.signature_date);
        }

        if (sale.legacy_data && sale.legacy_data.date_signed) {
            sale.legacy_data.date_signed = new Date(sale.legacy_data.date_signed);
        }

        if (sale.date_modified) {
            sale.date_modified = new Date(sale.date_modified);
        }

        sale.date_created = new Date(sale.date_created);
    }
    return body;
};

export const updateSale = async (dispatch: any, token: string, sale: Sale) => {
    await performApiRequest(dispatch, '/sales/' + sale.id, 'PUT', token, sale);
};

export const createSale = async (dispatch: any, token: string, sale: Sale) : Promise<number> => {
    const response = await performApiRequest(dispatch, '/sales', 'POST', token, sale);
    const id = parseInt(response.headers.get('Id') as string);
    return id;
};

export const deleteSale = async (dispatch: any, token: string, id: number) => {
    await performApiRequest(dispatch, '/sales/' + id, 'DELETE', token);
};