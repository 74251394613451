import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Alert } from 'react-bootstrap';
import { fetchUserProfile } from '../../Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import LogoutTimer from './LogoutTimer';

export default function NavbarWidget()
{
    const session_state = useSelector((state: any) => state.auth.session_state);
    const profile = useSelector((state: any) => state.auth.profile);
    const load_state = useSelector((state: any) => state.auth.profile_load_state);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    switch(load_state.state) {
        case 'not_loaded':
            dispatch(fetchUserProfile());
            return <div></div>;
        case 'loading':
            return <Spinner as="span" size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
        case 'error':
            return <Alert variant="danger">Došlo k chybě</Alert>
    }
    return <span>
        <LogoutTimer error={session_state.load_state.state === 'error'} valid_until={session_state.valid_until} />&nbsp;&nbsp;
        Přihlášen jako <a href="##" onClick={() => navigate('profile')}>{profile.display_name}</a>
    </span>
}