import { useForm, SubmitHandler } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { isValidEmail } from '../Common/Validations';

type Inputs = {
    name: string,
    lead_id?: number,
    email: string,
    phone: string,
    ic: string,
    address: string,
    bank_acc: string,
    contact_person: string,
    notes: string
};

type Props = {
    customer: Customer,
    lead?: Lead,
    onDeleteClick: Function,
    onCustomerFormSuccess: Function
}

export default function CustomerForm(props: Props) {
    let defaults;
    if (props.lead) {
        defaults = {
            email: props.lead.email,
            phone: props.lead.phone,
            name: props.lead.name,
            lead_id: props.lead.id
        };
    } else {
        defaults = props.customer;
    }

    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
        defaultValues: defaults
    });

    const onSubmit: SubmitHandler<Inputs> = data => {
        props.onCustomerFormSuccess(data);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>

            <input type="hidden" {...register("lead_id")} />

            <Row>
                <Col md={8}>
                    <Form.Group className="mb-3">
                        <Form.Label>Jméno / název <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="text" {...register("name", {required: "Jméno / název je nutné vyplnit"})} isInvalid={!!errors.name} />
                        <Form.Text className={errors.name ? "text-danger" : "text-success"}>{errors.name?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>IČ / RČ</Form.Label>
                        <Form.Control type="text" {...register("ic")} isInvalid={!!errors.ic} />
                        <Form.Text className={errors.ic ? "text-danger" : "text-success"}>{errors.ic?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            
            <Row>
                <Col md={8}>
                    <Form.Group className="mb-3">
                        <Form.Label>Adresa</Form.Label>
                        <Form.Control type="text" {...register("address")} isInvalid={!!errors.address} />
                        <Form.Text className={errors.address ? "text-danger" : "text-success"}>{errors.address?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Číslo účtu</Form.Label>
                        <Form.Control type="text" {...register("bank_acc")} isInvalid={!!errors.bank_acc} />
                        <Form.Text className={errors.bank_acc ? "text-danger" : "text-success"}>{errors.bank_acc?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            
            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Kontaktní osoba</Form.Label>
                        <Form.Control type="text" {...register("contact_person")} isInvalid={!!errors.contact_person} />
                        <Form.Text className={errors.contact_person ? "text-danger" : "text-success"}>{errors.contact_person?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Telefon</Form.Label>
                        <Form.Control type="text" {...register("phone")} isInvalid={!!errors.phone} />
                        <Form.Text className={errors.phone ? "text-danger" : "text-success"}>{errors.phone?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="text" {...register("email", {required: "Email je nutné vyplnit", validate: isValidEmail })} isInvalid={!!errors.email} />
                        <Form.Text className={errors.email ? "text-danger" : "text-success"}>{errors.email?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Poznámky</Form.Label>
                        <Form.Control as="textarea" {...register("notes")} isInvalid={!!errors.notes} />
                        <Form.Text className={errors.notes ? "text-danger" : "text-success"}>{errors.notes?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Button className="float-end" variant="success" type="submit">Uložit</Button>
            {props.customer ? <Button onClick={() => {
                if(window.confirm("Opravdu chcete zákazníka smazat? Tato operace je NEVRATNÁ!")) {
                    props.onDeleteClick(props.customer.id);
                }
            }} variant="danger" type="button">Smazat</Button> : ''}
        </Form>
    );
}