import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { fetchApiToken } from '../../Auth/authSlice';

interface Inputs {
    username: string,
    password: string
}

export default function LoginForm(props: any)
{
    const { register, handleSubmit } = useForm<Inputs>();
    const load_state = useSelector((state: any) => state.auth.load_state);

    const dispatch = useDispatch<any>();

    const onSubmit: SubmitHandler<Inputs> = data => {
        dispatch({type: 'global/resetState'});
        dispatch(fetchApiToken(data));
    }

    let button;
    switch(load_state.state) {
        case 'loading':
            button = <Button type="button" variant="primary" disabled={true}>
                <Spinner as="span" size="sm" animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
            </Button>;
            break;
        case 'loaded':
            button = <Button type="button" disabled={true} variant="success">Přihlášen</Button>
            break;
        default:
            button = <Button type="submit" variant="primary">Přihlásit</Button>
    }

    return <div style={{width: '250px', margin: 'auto', marginTop: '50px'}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Uživatelské jméno</Form.Label>
                        <Form.Control type="text" {...register("username")} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Heslo</Form.Label>
                        <Form.Control type="password" {...register('password')} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    {button}
                </Col>
            </Row>
        </Form>
    </div>;
}