import { performApiRequest } from '../Common/api'

export const fetchCallLogs = async (dispatch: any, token: string, parent_type: string, parent_id: number) => {
    const response = await performApiRequest(dispatch, '/call-logs/' + parent_type + '/' + parent_id, 'GET', token);
    const body = await response.json();
    for (const call_log of body) {
        call_log.date = new Date(call_log.date);
    }
    return body;
};

export const updateCallLog = async (dispatch: any, token: string, call_log: CallLog) => {
    await performApiRequest(dispatch, '/call-logs/' + call_log.id, 'PUT', token, call_log);
};

export const createCallLog = async (dispatch: any, token: string, parent_type: string, parent_id: number, call_log: CallLog) : Promise<number> => {
    const response = await performApiRequest(dispatch, '/call-logs/' + parent_type + '/' + parent_id, 'POST', token, call_log);
    const id = parseInt(response.headers.get('Id') as string);
    return id;
};

export const deleteCallLog = async (dispatch: any, token: string, id: number) => {
    await performApiRequest(dispatch, '/call-logs/' + id, 'DELETE', token);
};