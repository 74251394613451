import { performApiRequest } from '../Common/api'

export const fetchCustomers = async (dispatch: any, token: string) => {
    const response = await performApiRequest(dispatch, '/customers', 'GET', token);
    const body = await response.json();
    for (const customer of body) {
        if (customer.calculated_data && customer.calculated_data.last_sale_modify_date) {
            customer.calculated_data.last_sale_modify_date = new Date(customer.calculated_data.last_sale_modify_date);
        }
    }
    return body;
};

export const updateCustomer = async (dispatch: any, token: string, customer: Customer) => {
    await performApiRequest(dispatch, '/customers/' + customer.id, 'PUT', token, customer);
};

export const createCustomer = async (dispatch: any, token: string, customer: Customer) : Promise<number> => {
    const response = await performApiRequest(dispatch, '/customers', 'POST', token, customer);
    const id = parseInt(response.headers.get('Id') as string);
    return id;
};

export const deleteCustomer = async (dispatch: any, token: string, id: number) => {
    await performApiRequest(dispatch, '/customers/' + id, 'DELETE', token);
};