import UserForm from './UserForm';
import PasswordUpdateForm from './PasswordUpdateForm';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { createUser, deleteUser, updateUser, fetchUsers } from '../../User/usersSlice';
import { createFlashMessageWithDispatch } from '../../FlashMessages/utils';
import { fetchUserProfile } from '../../Auth/authSlice';

export default function UserDetail() {

    const { id } = useParams();
    let user = useSelector((state: any) => state.users.all_users.find((e: User) => e.id == id));
    let profile_load_state = useSelector((state: any) => state.auth.profile_load_state);
    let profile = useSelector((state: any) => state.auth.profile);
    user = user ? user : {} as User;

    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const handleBack = useCallback(() => navigate("/users"), []);

    const load_state = useSelector((state: any) => state.users.load_state);

    if (load_state.state === 'not_loaded') {
        dispatch(fetchUsers());
        return <div></div>;
    } 
    
    if (profile_load_state === 'not_loaded') {
        dispatch(fetchUserProfile());
        return <div></div>;
    }
    
    else if(load_state.state === 'loading' || profile_load_state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error' || profile_load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message ? load_state.error_message : profile_load_state.error_message}</Alert>
    }

    const handleDelete = (id: number) => {
        dispatch(deleteUser(id));
        createFlashMessageWithDispatch(dispatch, 'success', 'Uživatel byl smazán');
        navigate("/users");
    }
    
    const handleUserFormSuccess = (user: User) => {
        if (user.id) {
            dispatch(updateUser(user));
            createFlashMessageWithDispatch(dispatch, 'success', 'Uživatel byl upraven');
        } else {
            dispatch(createUser(user));
            createFlashMessageWithDispatch(dispatch, 'success', 'Uživatel byl založen');
        }
        navigate("/users");
    }

    let password_update_form = null;
    if (user.id) {
        password_update_form = <><div className="clearfix"></div><br />
            <h3>Nastavení nového hesla</h3>
            <PasswordUpdateForm user={user} />
        </>;
    }

    return <div>
        <div className="float-end">
            <Button variant="warning" onClick={handleBack}>Zpět</Button>
        </div>

        <h1>{user.name}</h1>

        <div className="clearfix"></div>

        <UserForm user={user} profile={profile} onDeleteClick={(id: number) => handleDelete(id)} onUserFormSuccess={handleUserFormSuccess} />

        {password_update_form}
    </div>;
};