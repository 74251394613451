import { Form, Spinner, Alert } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers } from '../../User/usersSlice';

export default function UserPicker(props: any) {
    const load_state = useSelector((state: any) => state.users.load_state);
    const users = useSelector((state: any) => state.users.all_users);
    const dispatch = useDispatch<any>();

    if (load_state.state === 'not_loaded') {
        dispatch(fetchUsers());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    const options = users.map((user: User) => <option key={user.id} value={user.id}>{user.display_name ? user.display_name : user.username}</option>);
    options.unshift(<option key="0" value=""></option>);

    return <Form.Select type="number" {...props}>
        {options}
    </Form.Select>
}