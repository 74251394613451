import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { removeFlash } from '../../FlashMessages/flashMessagesSlice';

export default function FlashMessages() {
    const messages = useSelector((state: any) => state.flashMessages.visible);
    const dispatch = useDispatch();
    
    return <div>
        {messages.map((message: FlashMessage) => {
            const class_name = "flashMessage" + (message.invalid ? " invalid" : "");
            return <Alert className={class_name} key={message.id} variant={message.type} onClose={() => dispatch({type: removeFlash.type, payload: message.id})}>{message.content}</Alert>;
        })}
    </div>
};