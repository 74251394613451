import './App.scss';
import "react-datepicker/dist/react-datepicker.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import BsNavbar from 'react-bootstrap/Navbar';
import { Route, Outlet, NavLink, useNavigate, ScrollRestoration, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import CustomersDatagrid from './components/Customer/CustomersDatagrid';
import CustomerDetail from './components/Customer/CustomerDetail';
import SalesDatagrid from './components/Sale/SalesDatagrid';
import SaleDetail from './components/Sale/SaleDetail';
import LoginForm from './components/User/LoginForm';
import FlashMessages from './components/Common/FlashMessages';
import NavbarWidget from './components/User/NavbarWidget';
import UsersDatagrid from './components/User/UsersDatagrid';
import UserDetail from './components/User/UserDetail';
import Profile from './components/Profile/Profile';
import Sidebar from './components/Calendar/Sidebar';
import Dashboard from './components/Dashboard/Dashboard';
import SimpleSalesReportDatagrid from './components/Reporting/SimpleSalesReportDatagrid';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import { cs } from 'date-fns/locale';
import { logOut } from './Auth/authSlice';
import TaskModal from './components/Calendar/TaskModal';

import { updateSessionState } from './Auth/authSlice';
import LeadDetail from './components/Lead/LeadDetail';

registerLocale('cs', cs);
setDefaultLocale('cs');

export default function App() {
    const token = useSelector((state: any) => state.auth.api_token);
    const role = useSelector((state: any) => state.auth.profile?.role);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        setInterval(() => token ? dispatch(updateSessionState()) : null, 10000);
    }, [token]);


    if (token === null || token === undefined) {
        return <LoginForm />
    }

    const admin_routes = [];
    if (role === undefined || role === 'admin') {
        admin_routes.push(<Route key="91" path="users" element={<UsersDatagrid />} />);
        admin_routes.push(<Route key="92" path="user/:id" element={<UserDetail />} />);
        admin_routes.push(<Route key="93" path="user" element={<UserDetail />} />);
        admin_routes.push(<Route key="94" path="reporting/simple-sales" element={<SimpleSalesReportDatagrid />} />);
    }

    const router = createBrowserRouter(
        createRoutesFromElements(<Route path="/" element={<Layout />}>
            <Route key="01" index element={<Dashboard />} />
            <Route key="02" path="dashboard" element={<Dashboard />} />
            <Route key="03" path="customers" element={<CustomersDatagrid />} />
            <Route key="04" path="customer/new/:lead_id" element={<CustomerDetail />} />
            <Route key="05" path="customer/:id/:lead_id" element={<CustomerDetail />} />
            <Route key="05" path="customer/:id" element={<CustomerDetail />} />
            <Route key="05" path="customer" element={<CustomerDetail />} />
            <Route key="06" path="sales" element={<SalesDatagrid />} />
            <Route key="07" path="sale/:id/:customer_id" element={<SaleDetail />} />
            <Route key="07" path="sale/from-lead/:lead_id" element={<SaleDetail />} />
            <Route key="08" path="sale/:id" element={<SaleDetail />} />
            <Route key="09" path="sale" element={<SaleDetail />} />
            <Route key="10" path="profile" element={<Profile />} />
            <Route key="11" path="lead/:id" element={<LeadDetail />} />
            {admin_routes}
        </Route>
        )
    );

    return (
        <div>
            <RouterProvider router={router} />
        </div>
    );
}

function Layout() {
    const role = useSelector((state: any) => state.auth.profile?.role);
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    return <div>
        <BsNavbar bg="dark" expand="lg" variant="dark">
            <Container fluid>
                <BsNavbar.Brand style={{cursor: 'pointer'}} onClick={() => navigate('dashboard')}><img 
                    src={process.env.PUBLIC_URL + '/logo.png'}
                    width="60px"
                    className="d-inline-block align-top me-4"
                    alt="CRM II" />CRM II</BsNavbar.Brand>
                <BsNavbar.Toggle />
                <BsNavbar.Collapse id="main-navbar">
                    <Nav className="me-auto" key="1">
                        <Nav.Link key="dashboard" as={NavLink} to="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link key="customers" as={NavLink} to="/customers">Zákazníci</Nav.Link>
                        <Nav.Link key="sales" as={NavLink} to="/sales">Obchody</Nav.Link>
                        {role === 'admin' ? <Nav.Link key="users" as={NavLink} to="/users">Uživatelé</Nav.Link> : null}
                        {role === 'admin' ? <Nav.Link key="report" as={NavLink} to="/reporting/simple-sales">Report</Nav.Link> : null}
                    </Nav>
                    <Nav className="d-flex" key="2">
                        <BsNavbar.Text>
                            <NavbarWidget />&nbsp;&nbsp;|
                        </BsNavbar.Text>
                        <Nav.Link onClick={() => dispatch(logOut())}>Odhlásit</Nav.Link>
                    </Nav>
                </BsNavbar.Collapse>
            </Container>
        </BsNavbar>
        <Container fluid>
            <Row>
                <Col className="col-xl-10 col-12" key="1">
                    <br />
                    <Outlet />
                </Col>
                <Col className="col-xl-2 col-12" key="2" style={{marginTop: "20px", borderLeft: "4px solid var(--bs-secondary)"}}>
                    <Sidebar />
                </Col>
            </Row>
        </Container>

        <FlashMessages />
        <TaskModal />
            <ScrollRestoration />
    </div>;
}