import { performApiRequest } from '../Common/api'

export const fetchLeads = async (dispatch: any, token: string) => {
    const response = await performApiRequest(dispatch, '/leads', 'GET', token);
    const body = await response.json();
    
    for (const lead of body) {
        lead.date_created = new Date(lead.date_created);
    }

    return body;
};

export const deleteLead = async (dispatch: any, token: string, id: number) => {
    await performApiRequest(dispatch, '/leads/' + id, 'DELETE', token);
};

export const setCustomer = async (dispatch: any, token: string, lead_id: number, customer_id: number) => {
    await performApiRequest(dispatch, '/leads/' + lead_id + '/set-customer/' + customer_id, 'PUT', token);
};

export const setSale = async (dispatch: any, token: string, lead_id: number, sale_id: number) => {
    await performApiRequest(dispatch, '/leads/' + lead_id + '/set-sale/' + sale_id, 'PUT', token);
};