import config from '../config.json';
import { performApiRequest } from '../Common/api';

export const fetchApiToken = async (username: string, password: string) => {
    const response = await fetch(config.API_URL + '/get-token', {
        method: 'POST',
        body: JSON.stringify({
            username: username,
            password: password
        })
    });

    if (!response.ok) {
        throw "Error";
    }

    const body = await response.json();

    return body;
};

export const fetchUserProfile = async (dispatch: any, token: string) => {
    const response = await performApiRequest(dispatch, '/profile', 'GET', token);
    return await response.json();
}

export const expireApiToken = async (token: string) => {
    await fetch(config.API_URL + '/invalidate-token', {
        method: 'POST',
        body: JSON.stringify({token: token})
    });
}

export const updatePassword = async (dispatch: any, token: string, former_password: string, new_password: string) => {
    const response = await performApiRequest(dispatch, '/profile/update-password', 'POST', token, {
        former_password: former_password,
        new_password: new_password
    });
    return response;
}

export const updateProfile = async (dispatch: any, token: string, data: any) => {
    const response = await performApiRequest(dispatch, '/profile', 'PUT', token, data);
    return response;
}

export const updateSessionState = async (dispatch: any, token: string) => {
    const response : any = await performApiRequest(dispatch, '/session-state', 'GET', token);
    const body = await response.json();
    if (body.valid_until) {
        body.valid_until = new Date(body.valid_until);
    }
    return body;
}