import { useState, useEffect } from 'react';
import { Table, Form, Pagination } from 'react-bootstrap';
import { format } from 'date-fns';
import { numfor } from '../../Common/formats';

interface Props<ModelType extends {}> {
    data: ModelType[],
    headers: Object,
    onRowClick: Function,
    default_order: Order
    autofocus?: string
}

export interface Order {
    column: string,
    direction: 1|-1
}

export default function Datagrid<ModelType extends {}>(props: Props<ModelType>) {
    // get current filter state
    const [filters, setFilters] = useState({} as ModelType);

    // get current ordering state, default is always ID
    // @todo pass default in props
    const [ordering, setOrdering] = useState(props.default_order);

    // get current pagination state
    const [page, setPage] = useState(1);

    // autofocus
    useEffect(() => {if (props.autofocus) (document.querySelector('#filter' + props.autofocus + ' input') as HTMLElement).focus()}, [])

    // callback for filter changes
    const handleFilter = (column: string, value: any) => {
        let filters_copy = {...filters} as ModelType;
        filters_copy[column as keyof typeof filters] = value;
        setFilters(filters_copy);
    };
    // callback for sorting changes
    const handleSort = (column: string) => {
        let order_copy = {...ordering} as Order;

        if (order_copy.column == column) {
            order_copy.direction = order_copy.direction == 1 ? -1 : 1;
        } else {
            order_copy.column = column;
            order_copy.direction = 1;
        }
        setOrdering(order_copy);
    };

    // callback for pagination changes
    const handlePage = (page: number) => {
        setPage(page);
    };


    // first, the result data is filtered
    let filtered_rows = props.data.filter((row) => {
        let pass: boolean = true;
        Object.keys(filters).forEach((key: string) => {
            let parsed_filter_value = filters[key as keyof ModelType]?.toString();
            parsed_filter_value = parsed_filter_value ? parsed_filter_value : '';
            parsed_filter_value = parsed_filter_value.normalize("NFKD").replace(/\p{Diacritic}/gu, "").toLowerCase();

            if (parsed_filter_value == '') {
                return true;
            }

            let row_value = row[key as keyof ModelType] as any;
            if (row_value instanceof Date) {
                row_value = row_value ? format(row_value as Date, 'd. M. yyyy') : undefined;
            } else {
                row_value = row[key as keyof ModelType] ? row[key as keyof ModelType]?.toString() : '';
                row_value = row_value.normalize("NFKD").replace(/\p{Diacritic}/gu, "").toLowerCase();
            }

            if (row_value?.includes(parsed_filter_value)) {
                pass = true;
            } else {
                pass = false;
            }
        });
        return pass;
    });

    // then we apply sorting on the filtered data
    filtered_rows.sort((a, b) => {
        let column: keyof ModelType = ordering.column as keyof ModelType;

        let a_string = '';
        let b_string = '';
        if (a[column] instanceof Date) {
            let a_tmp = a[column] ? a[column] as Date : null;
            let b_tmp = b[column] ? b[column] as Date : null;
            a_string = a_tmp ? a_tmp.getTime().toString() : '';
            b_string = b_tmp ? b_tmp.getTime().toString() : '';
        } else {
            a_string = a[column]?.toLocaleString() as string;
            b_string = b[column]?.toLocaleString() as string;
        }
        
        let order: number = ('' + a_string).localeCompare(b_string ? b_string : '', undefined, {numeric: true, sensitivity: 'base'});
        return ordering.direction * order;
    });

    // and finally slice the portion of data for the current page
    let total_pages = Math.ceil(filtered_rows.length / 50);
    let pages = [];
    let display_dots = false;
    for (let number = 1; number <= total_pages; number++) {

        if (total_pages > 10 && number !== 1 && number !== total_pages && (number > (page + 3) || number < (page - 3))) {
            display_dots = true;
            continue;
        }

        if (display_dots) {
            display_dots = false;
            pages.push(<Pagination.Item key={number + '-dots'}>...</Pagination.Item>);
        }

        pages.push(
            <Pagination.Item key={number} active={number === page} onClick={() => number !== page ? handlePage(number) : null}>{number}</Pagination.Item>
        );
    }
    filtered_rows = filtered_rows.slice((page - 1) * 50, page * 50);

    // get column headers, filters and data using the header definition from props
    const column_headers: any[] = [];
    const filter_headers: any[] = [];
    Object.entries(props.headers).forEach(([key, definition]) => {
        column_headers.push(<th key={key} style={definition.style ? definition.style : {}}>
                <a href="#!" onClick={(e) => handleSort(key)}>
                    {definition.title}
                    {ordering.column != key ? "⇕" : (ordering.direction == 1 ? "⇑" : "⇓")}
                </a>
            </th>);
        filter_headers.push(<th id={"filter" + key} key={"filter" + key}><Form.Control onChange={(e) => handleFilter(key, e.target.value)} /></th>);
    });

    const totals = {};

    var i = -1;
    const data_rows: any[] = filtered_rows.map((row) => {
        const columns = [];
        for (let key of Object.keys(props.headers)) {
            let value : any = row[key as keyof ModelType];

            let header_definition = props.headers[key as keyof typeof props.headers] as any;
            
            if (header_definition.total !== undefined) {
                totals[key as keyof typeof props.headers] = totals[key as keyof typeof props.headers] ? totals[key as keyof typeof props.headers] + value : value;
            }

            value = value instanceof Date ? format(value, 'd. M. yyyy') : value;

            if (header_definition.render_callback) {
                value = header_definition.render_callback(value);
            }

            columns.push(<td key={key}>{value}</td>);
        }

        i = i + 1;
        return <tr key={i} onDoubleClick={() => props.onRowClick(row)}>{columns}</tr>;
    });

    let totals_row = null;
    if (Object.keys(totals).length > 0) {
        let totals_columns = [];
        for (let key of Object.keys(props.headers)) {
            if (totals[key as keyof typeof props.headers]) {
                totals_columns.push(<th>{numfor(totals[key as keyof typeof props.headers] as unknown as number)}</th>);
            } else {
                totals_columns.push(<th></th>);
            }
        }
        totals_row = <tfoot><tr>{totals_columns}</tr></tfoot>;
    }

    // render simple bootstrap table with header and filter rows and pagination
    return (
        <div>
            {pages.length > 1 ? <Pagination>{pages}</Pagination> : ''}
            <Table striped bordered hover>
                <thead>
                    <tr key="headers">
                        {column_headers}
                    </tr>
                    <tr key="filters">
                        {filter_headers}
                    </tr>
                </thead>
                <tbody>
                    {data_rows}
                </tbody>
                {totals_row}
            </Table>
            {pages.length > 1 ? <Pagination>{pages}</Pagination> : ''}
        </div>
    );
}