import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchSales as apiFetchSales, updateSale as apiUpdateSale, createSale as apiCreateSale, deleteSale as apiDeleteSale } from './api';
import { fetchCallLogs as apiFetchCallLogs, updateCallLog as apiUpdateCallLog, createCallLog as apiCreateCallLog, deleteCallLog as apiDeleteCallLog } from '../Common/callLogsApi';
import { uploadAttachment as apiUploadAttachment, deleteAttachment as apiDeleteAttachment } from '../Common/attachmentsApi';
import { refetchSimpleSalesReportDataIfLoaded } from '../Reporting/reportingSlice';
import { fetchLeads, setSale } from '../Lead/leadsSlice';


export const fetchSales = createAsyncThunk(
    'sales/fetchSales',
    async (_, { getState, dispatch }) : Promise<Sale[]> => {
        const state : any = getState();
        const sales = await apiFetchSales(dispatch, state.auth.api_token);
        return sales;
    }
)

export const updateSale = createAsyncThunk(
    'sale/updateSale',
    async (sale: Sale, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiUpdateSale(dispatch, state.auth.api_token, sale);
        dispatch(refetchSimpleSalesReportDataIfLoaded());
        return sale;
    }
)

export const createSale = createAsyncThunk(
    'sales/createSale',
    async (sale: Sale, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const id = await apiCreateSale(dispatch, state.auth.api_token, sale);
        sale.id = id as number;
        dispatch(refetchSimpleSalesReportDataIfLoaded());

        if (sale.lead_id) {
            dispatch(setSale({lead_id: sale.lead_id, related_obj_id: sale.id}));
        }

        return sale;
    }
)

export const deleteSale = createAsyncThunk(
    'sales/deleteSale',
    async (id: number, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteSale(dispatch, state.auth.api_token, id);
        dispatch(refetchSimpleSalesReportDataIfLoaded());
        dispatch(fetchLeads());
        return id;
    }
)

export const loadSaleCallLogs = createAsyncThunk(
    'sales/loadCallLogs',
    async (id: number, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const call_logs = await apiFetchCallLogs(dispatch, state.auth.api_token, 'sale', id);
        return { sale_id: id, call_logs: call_logs};
    }
)

export const updateCallLog = createAsyncThunk(
    'sales/updateCallLog',
    async (props : any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiUpdateCallLog(dispatch, state.auth.api_token, props.call_log);
        return { sale_id: props.sale_id, call_log: props.call_log};
    }
)

export const createCallLog = createAsyncThunk(
    'sales/createCallLog',
    async (props : any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const id = await apiCreateCallLog(dispatch, state.auth.api_token, 'sale', props.sale_id, props.call_log);
        props.call_log.id = id as number;
        return { sale_id: props.sale_id, call_log: props.call_log};
    }
)

export const deleteCallLog = createAsyncThunk(
    'sales/deleteCallLog',
    async (props: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteCallLog(dispatch, state.auth.api_token, props.id);
        return { sale_id: props.sale_id, id: props.id };
    }
)

export const uploadAttachment = createAsyncThunk(
    'sales/uploadAttachment',
    async (props: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const attachments = await apiUploadAttachment(dispatch, state.auth.api_token, 'sale', props.sale_id, props.form_data);
        return { sale_id: props.sale_id, attachments: attachments };
    }
)

export const deleteAttachment = createAsyncThunk(
    'sales/deleteAttachment',
    async (props: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteAttachment(dispatch, state.auth.api_token, 'sale', props.sale_id, props.id);
        return { sale_id: props.sale_id, id: props.id};
    }
)

const salesSlice = createSlice({
    name: "sales",
    initialState: {
        all_sales: [] as Sale[],
        load_state: {
            state: 'not_loaded'
        } as LoadState
    },
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(fetchSales.fulfilled, (state, action) => {
            state.all_sales = action.payload;
            state.load_state.state = 'loaded';
        });
        builder.addCase(fetchSales.pending, (state, action) => {
            state.load_state.state = 'loading';
        });
        builder.addCase(fetchSales.rejected, (state, action) => {
            state.load_state.state = 'error';
            state.load_state.error_message = action.payload as string;
        });
        builder.addCase(updateSale.fulfilled, (state, action) => {
            const index = state.all_sales.findIndex((e) => {
                return e.id === action.payload.id;
            });
            state.all_sales[index] = action.payload;
        });
        builder.addCase(createSale.fulfilled, (state, action) => {
            let sale = action.payload;
            state.all_sales.push(sale);
        });
        builder.addCase(deleteSale.fulfilled, (state, action) => {
            let id = action.payload;
            let index = state.all_sales.findIndex(a => a.id === id);
            state.all_sales.splice(index, 1);
        });
        builder.addCase(loadSaleCallLogs.fulfilled, (state, action) => {
            const call_logs = action.payload.call_logs;
            const sale_id = action.payload.sale_id;
            const index = state.all_sales.findIndex(a => a.id === sale_id);
            state.all_sales[index].call_logs = call_logs;
            state.all_sales[index].call_logs_loaded = true;
        });
        builder.addCase(createCallLog.fulfilled, (state, action) => {
            const call_log = action.payload.call_log;
            const sale_id = action.payload.sale_id;
            const index = state.all_sales.findIndex(a => a.id === sale_id);

            if (!state.all_sales[index].call_logs_loaded) {
                throw `Cannot update call logs for sale ${sale_id} - call logs not loaded`;
            }

            state.all_sales[index].call_logs?.push(call_log);
        });
        builder.addCase(updateCallLog.fulfilled, (state, action) => {
            let id = action.payload.sale_id;
            let call_log = action.payload.call_log;
            let index = state.all_sales.findIndex(a => a.id === id);
        
            if (!state.all_sales[index].call_logs_loaded) {
                throw `Cannot update call logs for sale ${id} - call logs not loaded`;
            }
        
            const call_logs = state.all_sales[index].call_logs;
        
            if (call_logs) {
                const call_log_index = call_logs.findIndex(a => a.id === call_log.id);
        
                if (call_log_index !== -1) {
                    call_logs[call_log_index] = call_log;
                }
            }
        });
        builder.addCase(deleteCallLog.fulfilled, (state, action) => {
            let id = action.payload.sale_id;
            let call_log_id = action.payload.id;
            let index = state.all_sales.findIndex(a => a.id === id);

            if (!state.all_sales[index].call_logs_loaded) {
                throw `Cannot update call logs for sale ${id} - call logs not loaded`;
            }

            const call_logs = state.all_sales[index].call_logs;

            if (call_logs) {
                const call_log_index = call_logs.findIndex(a => a.id === call_log_id);

                if (call_log_index !== -1) {
                    call_logs.splice(index, 1);
                }
            }

            state.all_sales[index].call_logs = call_logs;
        });
        builder.addCase(uploadAttachment.fulfilled, (state, action) => {
            let id = action.payload.sale_id;
            let index = state.all_sales.findIndex(a => a.id === id);

            const attachments = action.payload.attachments;

            for (const attachment of attachments) {
                state.all_sales[index].attachments?.push(attachment);
            }
        });
        builder.addCase(deleteAttachment.fulfilled, (state, action) => {
            let sale_id = action.payload.sale_id;
            let id = action.payload.id;
            let sale_index = state.all_sales.findIndex(a => a.id === sale_id);
            let attachment_index = state.all_sales[sale_index].attachments?.findIndex(a => a.id === id);

            if (attachment_index) {
                state.all_sales[sale_index].attachments?.splice(attachment_index, 1);
            }
        });
    }
});

export default salesSlice.reducer