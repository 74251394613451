import UserForm from './UserForm';
import PasswordUpdateForm from './PasswordUpdateForm';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Spinner } from 'react-bootstrap';
import { fetchUserProfile, updateUserProfile } from '../../Auth/authSlice';

export default function UserDetail() {

    const dispatch = useDispatch<any>();

    let profile = useSelector((state: any) => state.auth.profile);
    const load_state = useSelector((state: any) => state.auth.profile_load_state);

    if (load_state.state === 'not_loaded') {
        dispatch(fetchUserProfile());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }
    
    const handleProfileFormSuccess = (data: any) => {
        dispatch(updateUserProfile(data));
    }

    return <div>
        <h1>Profil</h1>

        <div className="clearfix"></div>

        <UserForm profile={profile} onUserFormSuccess={handleProfileFormSuccess} />

        <div className="clearfix"></div><br />
            <h3>Nastavení nového hesla</h3>
            <PasswordUpdateForm user={profile} />
    </div>;
};