import { performApiRequest } from '../Common/api'

export const uploadAttachment = async (dispatch: any, token: string, parent_type: string, parent_id: number, form_data: FormData) : Promise<void> => {
    const response = await performApiRequest(dispatch, '/attachments/upload/' + parent_type + '/' + parent_id, 'POST', token, form_data, 'FormData');
    const body = await response.json();
    return body;
};

export const deleteAttachment = async (dispatch: any, token: string, parent_type: string, parent_id: number, id: number) : Promise<void> => {
    await performApiRequest(dispatch, '/attachments/delete/' + parent_type + '/' + parent_id + '/' + id, 'DELETE', token);
};

export const downloadAttachment = async (dispatch: any, token: string, id: number) : Promise<any> => {
    const response = await performApiRequest(dispatch, '/attachments/download/' + id, 'GET', token);
    const blob = await response.blob();
    return blob;
};