//import store from "../store";
import { showFlash, invalidateFlash, removeFlash } from "./flashMessagesSlice";
import { v4 as uuid } from 'uuid';

export function createFlashMessageWithDispatch(dispatch: any, type: string, message: string) : void {
    const flash = {
        id: uuid(),
        type: type,
        content: message
    };
    dispatch({type: showFlash.type, payload: flash});
    setTimeout((e) => {
        dispatch({type: invalidateFlash.type, payload: flash.id});
        setTimeout((e) => {
            dispatch({type: removeFlash.type, payload: flash.id});
        }, 500);
    }, 5000);
}