import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Spinner, Alert } from 'react-bootstrap';
import { fetchLeads } from '../../Lead/leadsSlice';
import { format } from 'date-fns';

export default function LeadList() {
    const navigate = useNavigate();
    const handleCardClick = (lead: Lead) => navigate('/lead/' + lead.id);

    const load_state = useSelector((state: any) => state.leads.load_state);
    const leads = useSelector((state: any) => state.leads.all_leads);
    const dispatch = useDispatch<any>();

    if (load_state.state === 'not_loaded') {
        dispatch(fetchLeads());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    if (leads.length === 0) {
        return <></>
    }

    const cards = [];
    for (const lead of leads) {
        lead as Lead;
        cards.push(<Card key={lead.id} bg="secondary" style={{ width: '18rem', display: 'inline-block', margin: '5px' }}>
            <Card.Body onDoubleClick={() => handleCardClick(lead)}>
                <Card.Title>{lead.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{lead.car_name}</Card.Subtitle>
                <Card.Text>
                    E-mail: {lead.email}<br />
                    Telefon: {lead.email}
                </Card.Text>
                <Card.Footer>
                    <small className="text-muted">{lead.date_created ? format(lead.date_created, 'dd.MM.yyyy H:m') : ''}</small>
                </Card.Footer>
            </Card.Body>
      </Card>);
    }

    if (leads.length < 1) {
        return <></>;
    }


    return (
        <div>
            <h3>Poptávky z webu</h3>
            <div style={{whiteSpace: 'nowrap', overflowX: 'auto'}}>
                <div style={{margin: '10px'}}>{cards}</div>
            </div>
        </div>
    );
}