import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Datagrid, { Order } from '../Common/Datagrid';
import { Spinner, Alert } from 'react-bootstrap';
import { fetchSimpleSalesReportData } from '../../Reporting/reportingSlice';
import { numfor } from '../../Common/formats';

interface DatagridModel {
    id: number,
    state: string,
    date: Date,
    subject: string
    customer_name: string,
    import_gross_profit?: number,
    import_expeses?: number,
    import_net_profit?: number,
    import_defend_profit?: number,
    import_insurance_profit?: number,
    leasing_pd_leasing?: number,
    leasing_pd_insurance?: number,
    leasing_pd_other?: number,
    leasing_pd_all?: number,
    leasing_expenses?: number,
    leasing_profit?: number,
    sale_profit?: number
}

export default function SimpleSalesReportDatagrid() {
    const navigate = useNavigate();
    const handleRowClick = useCallback((row: DatagridModel) => navigate('/sale/' + row.id), []);

    const load_state = useSelector((state: any) => state.reporting.simple_sales_report_load_state);
    const report_rows = useSelector((state: any) => state.reporting.simple_sales_report_data);
    const dispatch = useDispatch<any>();

    if (load_state.state === 'not_loaded') {
        dispatch(fetchSimpleSalesReportData());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    const rows : DatagridModel[] = report_rows.map((row: SimpleSaleReportRow) => {
        return row as DatagridModel;
    });

    const base = {render_callback: (value: number) => numfor(value), total: true}

    const headers = {
        'id': {title: "#", style: { width: "100px" }},
        'state': {title: "Stav"},
        'date': {title: "Datum"},
        'subject': {title: "Věc"},
        'customer_name': {title: "Zákazník"},
        'import_gross_profit': {...{title: "Dovoz - hrubý zisk"}, ...base},
        'import_expeses': {...{title: "Dovoz - náklady"}, ...base},
        'import_net_profit': {...{title: "Dovoz - čistý zisk"}, ...base},
        'import_defend_profit': {...{title: "Dovoz - defend zisk"}, ...base},
        'import_insurance_profit': {...{title: "Dovoz - pojistka zisk"}, ...base},
        'leasing_pd_leasing': {...{title: "Úvěr - PD úvěr"}, ...base},
        'leasing_pd_insurance': {...{title: "Úvěr - PD pojistka"}, ...base},
        'leasing_pd_other': {...{title: "Úvěr - PD ostatní"}, ...base},
        'leasing_pd_all': {...{title: "Úvěr - PD součet"}, ...base},
        'leasing_expenses': {...{title: "Úvěr - náklady"}, ...base},
        'leasing_profit': {...{title: "Úvěr - zisk"}, ...base},
        'sale_profit': {...{title: "Prodej - zisk", ...base}}
    };

    const default_order = {
        column: 'date',
        direction: -1
    } as Order;

    return (
        <div style={{overflow: 'auto'}}>
            <Datagrid data={rows} headers={headers} onRowClick={handleRowClick} default_order={default_order} />
        </div>
    );
}