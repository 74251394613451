import { useForm, SubmitHandler } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { profile } from "console";
import userEvent from "@testing-library/user-event";

type Inputs = {
    username: string,
    display_name: string,
    role: 'admin' | 'sales'
};

type Props = {
    user: User,
    profile: User,
    onDeleteClick: Function,
    onUserFormSuccess: Function
}

export default function UserForm(props: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
        defaultValues: props.user
    });

    const onSubmit: SubmitHandler<Inputs> = data => {
        props.onUserFormSuccess(data);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Login <span className="text-danger">*</span></Form.Label>
                        <Form.Control type="text" {...register("username", {required: "Login je nutné vyplnit", maxLength: 20})} isInvalid={!!errors.username} />
                        <Form.Text className={errors.username ? "text-danger" : "text-success"}>{errors.username?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Role <span className="text-danger">*</span></Form.Label>
                        <Form.Select {...register("role", {required: "Roli je nutné vyplnit"})} isInvalid={!!errors.role}>
                            <option value="admin">Administrátor</option>
                            <option value="sales">Obchodník</option>
                        </Form.Select>
                        <Form.Text className={errors.role ? "text-danger" : "text-success"}>{errors.role?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            
            <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Celé jméno</Form.Label>
                        <Form.Control type="text" {...register("display_name", {maxLength: 200})} isInvalid={!!errors.display_name} />
                        <Form.Text className={errors.display_name ? "text-danger" : "text-success"}>{errors.display_name?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            {props.profile.id != props.user.id ? <Button className="float-end" variant="success" type="submit">Uložit</Button> : ''}
            {props.user && props.profile.id != props.user.id ? <Button onClick={() => {
                if(window.confirm("Opravdu chcete uživatele smazat? Tato operace je NEVRATNÁ!")) {
                    props.onDeleteClick(props.user.id);
                }
            }} variant="danger" type="button">Smazat</Button> : ''}
        </Form>
    );
}