import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface State {
    modal_open: boolean,
    current_task?: Task
}

const tasksModalSlice = createSlice({
    name: "calendar_tasks_modal",
    initialState: {
        modal_open: false,
        current_task: undefined
    } as State,
    reducers: {
        openWithNew(state, action?: PayloadAction<Date | undefined>) {
            state.modal_open = true;
            state.current_task = {date: action?.payload ? action.payload : undefined} as Task;
        },

        openWithUpdate(state, action: PayloadAction<Task>) {
            state.modal_open = true;
            state.current_task = action.payload;
        },

        close(state) {
            state.modal_open = false;
            state.current_task = undefined;
        }
    }
});

export const { openWithNew, openWithUpdate, close } = tasksModalSlice.actions;
export default tasksModalSlice.reducer