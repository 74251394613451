import { useState } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createCallLog, updateCallLog, deleteCallLog } from "../../Customer/customersSlice";
import { createCallLog as createCallLogSale, updateCallLog as updateCallLogSale, deleteCallLog as deleteCallLogSale } from "../../Sale/salesSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

type Inputs = {
    id?: number | string,
    date: Date,
    subject: string,
    task: string
}

type Props = {
    parent: Customer | Sale
}

export default function CallLog(props: Props) {
    let rows = props.parent.call_logs;
    rows = rows !== undefined ? rows : [] as CallLog[];
    
    const [show_modal, setShowModal] = useState(false);
    const [current_call_log, setCurrentCallLog] = useState({} as CallLog);

    const { control, register, handleSubmit, formState: { errors }, reset } = useForm<Inputs>({
        defaultValues: {
            date: current_call_log.date,
            subject: current_call_log.subject,
            task: current_call_log.task
        }
    });

    const dispatch = useDispatch<any>();

    const onRowClick = (id: number | string) => {
        let selected_row = rows?.find((e: CallLog) => e.id === id);

        if (selected_row) {
            const copy = {...selected_row};
            setCurrentCallLog(copy);
            setShowModal(true);
            reset({
                id: copy.id,
                date: copy.date,
                subject: copy.subject,
                task: copy.task
            });
        }
    }

    const onNewClick = () => {
        setCurrentCallLog({} as CallLog);
        setShowModal(true);
        reset({} as Inputs);
    }


    const onCloseModal = () => {
        setCurrentCallLog({} as CallLog);
        setShowModal(false);
        reset({} as Inputs);
    }


    const isCustomer = (parent: Customer | Sale) : parent is Customer => {
        if ((parent as Sale).subject) return false;
        return true;
    }


    const onDeleteClick = (id: number | string) =>{
        if (isCustomer(props.parent)) {
            dispatch(deleteCallLog({customer_id: props.parent.id, id: id}));
        } else {
            dispatch(deleteCallLogSale({sale_id: props.parent.id, id: id}));
        }
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if (data.id) {
            if (isCustomer(props.parent)) {
                dispatch(updateCallLog({customer_id: props.parent.id, call_log: data}));
            } else {
                dispatch(updateCallLogSale({sale_id: props.parent.id, call_log: data}));
            }
        } else {
            if (isCustomer(props.parent)) {
                dispatch(createCallLog({customer_id: props.parent.id, call_log: data}));
            } else {
                dispatch(createCallLogSale({sale_id: props.parent.id, call_log: data}));
            }
        }

        setShowModal(false);
        reset({} as Inputs);
    }

    return (
        <div>
            <div className="float-end">
                <Button variant="primary" onClick={onNewClick}>Nové volání</Button>
            </div>
            <h2>Kniha volání</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Věc</th>
                        <th>Úkol</th>
                        <th style={{width: "50px"}}></th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row) => {
                        return <tr key={row.id} onDoubleClick={() => onRowClick(row.id)}>
                            <td>{row.date.toLocaleDateString("cs-CZ")}</td>
                            <td>{row.subject}</td>
                            <td>{row.task}</td>
                            <td>
                                <a href="#!" title="Odstranit" onClick={() => {
                                    if(window.confirm("Opravdu chcete volání smazat? Tato operace je NEVRATNÁ!")) {
                                        onDeleteClick(row.id)
                                    }
                                }}>
                                    <FontAwesomeIcon className="text-danger" icon={faTrash} />
                                </a>
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>

            <Modal show={show_modal} onHide={onCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Volání</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Datum <span className="text-danger">*</span></Form.Label>
                                    
                                    <Controller control={control} rules={{required: "Datum je nutné vyplnit"}} name="date" render={({field}) => (
                                        <DatePicker className={(!!errors.date ? "is-invalid " : "") + "form-control"} 
                                            onChange={(date) => field.onChange(date)} selected={field.value} dateFormat="d.M.yyyy" />
                                    )} />
                                    
                                    <Form.Text className={errors.date ? "text-danger" : "text-success"}>{errors.date?.message}&nbsp;</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Věc <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" {...register("subject", {required: "Věc je nutné vyplnit"})} isInvalid={!!errors.subject} />
                                    <Form.Text className={errors.subject ? "text-danger" : "text-success"}>{errors.subject?.message}&nbsp;</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Úkol</Form.Label>
                                    <Form.Control as="textarea" type="text" {...register("task")} isInvalid={!!errors.task} />
                                    <Form.Text className={errors.task ? "text-danger" : "text-success"}>{errors.task?.message}&nbsp;</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCloseModal}>Zavřít</Button>
                    <Button variant="primary" onClick={handleSubmit((e) => onSubmit(e))}>Uložit</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}