import { useCallback, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Datagrid, { Order } from '../Common/Datagrid';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { fetchCustomers } from '../../Customer/customersSlice';
import SaleState from '../Common/SaleState';

interface DatagridModel {
    id?: number,
    name?: string,
    ic?: string,
    email?: string,
    phone?: string,
    last_sale_state?: ReactElement,
    last_sale_modify_date?: Date,
    sales_count?: number
}

export default function CustomersDatagrid() {
    const navigate = useNavigate();
    const handleRowClick = useCallback((row: DatagridModel) => navigate('/customer/' + row.id), []);

    const load_state = useSelector((state: any) => state.customers.load_state);
    const customers = useSelector((state: any) => state.customers.all_customers);
    const dispatch = useDispatch<any>();

    if (load_state.state === 'not_loaded') {
        dispatch(fetchCustomers());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    const rows : DatagridModel[] = customers.map((customer: Customer) => {
        return {
            id: customer.id,
            name: customer.name + (customer.contact_person ? (" (" + customer.contact_person + ")") : ""),
            ic: customer.ic,
            phone: customer.phone,
            last_sale_subject: customer.calculated_data?.last_sale_subject,
            last_sale_state: <SaleState state={customer.calculated_data?.last_sale_state} />,
            last_sale_modify_date: customer.calculated_data?.last_sale_modify_date,
            sales_count: customer.calculated_data?.sales_count
        } as DatagridModel;
    });

    const headers = {
        'id': {title: "#", style: { width: "100px" }},
        'name': {title: "Jméno / název (Osoba)"},
        'ic': {title: "IČ"},
        'phone': {title: "Telefon"},
        'last_sale_subject': {title: "Poslední obchod"},
        'last_sale_state': {title: "Stav posledního obchodu"},
        'last_sale_modify_date': {title: "Datum změny posledního obchodu"},
        'sales_count': {title: "Počet obchodů", style: { width: "100px" }}
    };

    const default_order = {
        column: 'last_sale_modify_date',
        direction: -1
    } as Order;

    return (
        <div>
            <Button className="float-end mb-3" variant="primary" onClick={() => navigate('/customer')}>Nový zákazník</Button>
            <Datagrid data={rows} headers={headers} onRowClick={handleRowClick} default_order={default_order} autofocus="name" />
        </div>
    );
}