import { useCallback, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Datagrid, { Order } from '../Common/Datagrid';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { fetchUsers } from '../../User/usersSlice';

interface DatagridModel {
    id?: number,
    username?: string,
    display_name?: string,
    role?: string
}

export default function UsersDatagrid() {
    const navigate = useNavigate();
    const handleRowClick = useCallback((row: DatagridModel) => navigate('/user/' + row.id), []);

    const load_state = useSelector((state: any) => state.users.load_state);
    const users = useSelector((state: any) => state.users.all_users);
    const dispatch = useDispatch<any>();

    if (load_state.state === 'not_loaded') {
        dispatch(fetchUsers());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    const rows : DatagridModel[] = users.map((user: User) => {
        return {
            id: user.id,
            role: user.role,
            username: user.username,
            display_name: user.display_name
        } as DatagridModel;
    });

    const headers = {
        'id': {title: "#", style: { width: "100px" }},
        'role': {title: "Role"},
        'username': {title: "Login"},
        'display_name': {title: "Jméno"}
    };

    const default_order = {
        column: 'username',
        direction: -1
    } as Order;

    return (
        <div>
            <Button className="float-end mb-3" variant="primary" onClick={() => navigate('/user')}>Nový uživatel</Button>
            <Datagrid data={rows} headers={headers} onRowClick={handleRowClick} default_order={default_order} />
        </div>
    );
}