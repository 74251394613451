import CustomerForm from './CustomerForm';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Attachments from '../Common/Attachments';
import { Button, Alert, Spinner } from 'react-bootstrap';
import SalesDatagrid from '../Sale/SalesDatagrid';
import CallLog from '../Common/CallLog';
import { useParams, useNavigate } from 'react-router-dom';
import { createCustomer, deleteCustomer, updateCustomer, loadCustomerCallLogs, fetchCustomers } from '../../Customer/customersSlice';
import { createFlashMessageWithDispatch } from '../../FlashMessages/utils';
import { fetchLeads } from '../../Lead/leadsSlice';

export default function CustomerDetail() {

    const { id, lead_id } = useParams();
    let customer = useSelector((state: any) => state.customers.all_customers.find((e: Customer) => e.id == id));
    let lead = useSelector((state: any) => state.leads.all_leads.find((e: Lead) => e.id == lead_id));
    let lead_load_state = useSelector((state: any) => state.leads.load_state);

    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    if (lead_load_state.state === 'not_loaded') {
        dispatch(fetchLeads());
    }

    customer = customer ? customer : {} as Customer;

    const handleBack = useCallback(() => lead ? navigate("/lead/" + lead.id) : navigate("/customers"), []);

    const load_state = useSelector((state: any) => state.customers.load_state);

    if (load_state.state === 'not_loaded') {
        dispatch(fetchCustomers());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    if (customer.id && !customer.call_logs_loaded) {
        dispatch(loadCustomerCallLogs(customer.id));
    }

    const handleDelete = (id: number) => {
        dispatch(deleteCustomer(id));
        createFlashMessageWithDispatch(dispatch, 'success', 'Zákazník byl smazán');
        navigate("/customers");
    }
    
    const handleCustomerFormSuccess = (customer: Customer) => {
        if (customer.id) {
            dispatch(updateCustomer(customer));
            createFlashMessageWithDispatch(dispatch, 'success', 'Zákazník byl upraven');
        } else {
            dispatch(createCustomer(customer));
            createFlashMessageWithDispatch(dispatch, 'success', 'Zákazník byl založen');
        }

        if (lead) {
            navigate("/lead/" + lead.id);
        } else {
            navigate("/customers");
        }
    }

    const additional_components = customer.id ? <div>

        <Attachments parent={customer} attachments={customer.attachments} />

        <div className="clearfix"><br /></div>

        <h2>Obchody</h2>

        <SalesDatagrid customer={customer} hide_state_filter={true} />

        <div className="clearfix"><br /></div>

        <CallLog parent={customer} />
    </div> : '';

    return <div>
        <div className="float-end">
            <Button variant="warning" onClick={handleBack}>Zpět</Button>
        </div>

        <h1>{customer.name}</h1>

        <div className="clearfix"></div>

        <CustomerForm customer={customer} lead={lead} onDeleteClick={(id: number) => handleDelete(id)} onCustomerFormSuccess={handleCustomerFormSuccess} />

        <div className="clearfix"></div>

        {additional_components}
    </div>;
};