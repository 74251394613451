import { Button, Badge, Spinner, Alert, Modal, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from "react-redux";
import { fetchTasks } from "../../Calendar/calendarSlice";
import { fetchUsers } from "../../User/usersSlice";
import { openWithNew,openWithUpdate } from "../../Calendar/tasksModalSlice";
import uniqolor from "uniqolor";
import { startOfTomorrow } from "date-fns";

export default function Sidebar(props: any) 
{
    const load_state = useSelector((state: any) => state.calendar.load_state);
    const users_load_state = useSelector((state: any) => state.users.load_state);
    const all_users = useSelector((state: any) => state.users.all_users);
    const tasks = useSelector((state: any) => state.calendar.all_tasks);
    const role = useSelector((state: any) => state.auth.profile?.role);
    const dispatch = useDispatch<any>();

    if (users_load_state.state === 'not_loaded') {
        dispatch(fetchUsers());
    }

    if (load_state.state === 'not_loaded') {
        dispatch(fetchTasks());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    const onTaskClick = (id: number | string) => {
        let task = tasks.find((e: Task) => e.id === id);

        if (task) {
            const copy = {...task};
            dispatch(openWithUpdate(copy));
        }
    }

    const onNewClick = (date?: Date) => {
        dispatch(openWithNew(date));
    }

    const today = (new Date);
    today.setHours(0,0,0,0);
    const tomorrow = new Date(today.getTime());
    tomorrow.setDate(today.getDate() + 1);
    const seven_days = new Date(today.getTime());
    seven_days.setDate(today.getDate() + 7);

    const tasks_today = [];
    const tasks_tomorrow = [];
    const tasks_week = [];
    const tasks_no_date = [];

    const createTaskRender = (task: Task) => {
        const owner = users_load_state.state === 'loaded' ? all_users?.find((user: User) => user.id == task.owner_id) : undefined;
        const username = role === 'admin' ? <em style={{opacity: .7}}>({owner ? owner?.username : ''})</em> : null;
        return <div key={task.id}><Badge  
            onClick={() => onTaskClick(task.id as number)} 
            bg=""
            style={{cursor: "pointer", backgroundColor: (owner && role === 'admin' ? uniqolor(owner.username, {lightness: 50}).color : 'black')}}>
            {task.title} 
            {username}
        </Badge>&nbsp;</div>;
    }

    for (let task of tasks) {
        task = {...task};
        task.date = task.date ? new Date(task.date) : null;

        if (task.date == null || task.date == undefined) {
            tasks_no_date.push(createTaskRender(task));
        } else if (task.date.getTime() == today.getTime()) {
            tasks_today.push(createTaskRender(task));
        } else if (task.date.getTime() == tomorrow.getTime()) {
            tasks_tomorrow.push(createTaskRender(task));
        } else if (task.date.getTime() <= seven_days.getTime() && task.date.getTime() > tomorrow.getTime()) {
            tasks_week.push(createTaskRender(task));
        }
    }

    return <div>
        <Button onClick={() => onNewClick()} variant="secondary" size="sm" className="float-end"><FontAwesomeIcon icon={faPlus} /></Button>
        <h4>Bez datumu</h4>
        
        {tasks_no_date}
        
        <br /><br />
        <div className="clearfix"></div>

        <Button onClick={() => onNewClick(new Date)} variant="secondary" size="sm" className="float-end"><FontAwesomeIcon icon={faPlus} /></Button>
        <h4>Dnes</h4>
        
        {tasks_today}
        
        <br /><br />
        <div className="clearfix"></div>

        <Button onClick={() => onNewClick(startOfTomorrow())} variant="secondary" size="sm" className="float-end"><FontAwesomeIcon icon={faPlus} /></Button>
        <h4>Zítra</h4>
        
        {tasks_tomorrow}
        
        <br /><br />
        <div className="clearfix"></div>

        <h4>Brzy</h4>
        
        {tasks_week}
    </div>;
}