import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchSimpleSalesReportData as apifetchSimpleSalesReportData } from './api';

export const fetchSimpleSalesReportData = createAsyncThunk(
    'reporting/simpleSalesReportFetch',
    async (_, { getState, dispatch }) : Promise<SimpleSaleReportRow[]> => {
        const state : any = getState();
        const sales = await apifetchSimpleSalesReportData(dispatch, state.auth.api_token);
        return sales;
    }
)

export const refetchSimpleSalesReportDataIfLoaded = createAsyncThunk(
    'reporting/simpleSalesReportRefetchIfLoaded',
    async (_, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        if (state.reporting.simple_sales_report_load_state.state !== 'not_loaded') {
            dispatch(fetchSimpleSalesReportData());
        }
    }
)

const salesSlice = createSlice({
    name: "reporting",
    initialState: {
        simple_sales_report_data: [] as SimpleSaleReportRow[],
        simple_sales_report_load_state: {
            state: 'not_loaded'
        } as LoadState
    },
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(fetchSimpleSalesReportData.fulfilled, (state, action) => {
            state.simple_sales_report_data = action.payload;
            state.simple_sales_report_load_state.state = 'loaded';
        });
        builder.addCase(fetchSimpleSalesReportData.pending, (state, action) => {
            state.simple_sales_report_load_state.state = 'loading';
        });
        builder.addCase(fetchSimpleSalesReportData.rejected, (state, action) => {
            state.simple_sales_report_load_state.state = 'error';
            state.simple_sales_report_load_state.error_message = action.payload as string;
        });
    }
});

export default salesSlice.reducer