import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchUsers as apiFetchUsers, updateUser as apiUpdateUser, createUser as apiCreateUser, deleteUser as apiDeleteUser, updateuserPassword as apiUpdateUserPassword } from './api';
import { createFlashMessageWithDispatch } from '../FlashMessages/utils';

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async (_, { getState, dispatch }) : Promise<User[]> => {
        const state : any = getState();
        const users = await apiFetchUsers(dispatch, state.auth.api_token);
        return users;
    }
)

export const updateUser = createAsyncThunk(
    'users/updateUser',
    async (user: User, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiUpdateUser(dispatch, state.auth.api_token, user);
        return user;
    }
)

export const createUser = createAsyncThunk(
    'users/createUser',
    async (user: User, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const id = await apiCreateUser(dispatch, state.auth.api_token, user);
        user.id = id as number;
        return user;
    }
)

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (id: number, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteUser(dispatch, state.auth.api_token, id);
        return id;
    }
)

export const updateUserPassword = createAsyncThunk(
    'users/updatePassword',
    async (data: any, { getState, dispatch }) : Promise<any> => {
        const { id, password } = data;
        const state : any = getState();
        await apiUpdateUserPassword(dispatch, state.auth.api_token, id, password);
        createFlashMessageWithDispatch(dispatch, "success", "Heslo bylo změněno");
        return id;
    }
)

const usersSlice = createSlice({
    name: "users",
    initialState: {
        all_users: [] as User[],
        load_state: {
            state: 'not_loaded'
        } as LoadState
    },
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.all_users = action.payload;
            state.load_state.state = 'loaded';
        });
        builder.addCase(fetchUsers.pending, (state, action) => {
            state.load_state.state = 'loading';
        });
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.load_state.state = 'error';
            state.load_state.error_message = action.payload as string;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            const index = state.all_users.findIndex((e) => {
                return e.id === action.payload.id;
            });
            state.all_users[index] = action.payload;
        });
        builder.addCase(createUser.fulfilled, (state, action) => {
            let user = action.payload;
            state.all_users.push(user);
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            let id = action.payload;
            let index = state.all_users.findIndex(a => a.id === id);
            state.all_users.splice(index, 1);
        });
        builder.addCase(updateUserPassword.fulfilled, (state, action) => {
            const user = state.all_users.find((user: User) => user.id === action.payload);
            if (user && user.password_change_load_state) user.password_change_load_state.state = 'loaded';
        });
        builder.addCase(updateUserPassword.pending, (state, action) => {
            const user = state.all_users.find((user: User) => user.id === action.meta.arg.id);
            if (user && user.password_change_load_state) user.password_change_load_state.state = 'loading';
        });
        builder.addCase(updateUserPassword.rejected, (state, action) => {
            const user = state.all_users.find((user: User) => user.id === action.meta.arg.id);
            if (user && user.password_change_load_state) user.password_change_load_state.state = 'error';
        });
    }
});

export default usersSlice.reducer