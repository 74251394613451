import { Badge, Alert, Spinner, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { format, isSameDay } from "date-fns";
import { cs } from "date-fns/locale";
import { fetchUsers } from "../../User/usersSlice";
import { fetchTasks } from "../../Calendar/calendarSlice";
import { openWithNew, openWithUpdate } from "../../Calendar/tasksModalSlice";
import uniqolor from "uniqolor";

interface Props {
    year?: number,
    month?: number
}

export default function Calendar(props: Props) {
    const load_state = useSelector((state: any) => state.calendar.load_state);
    const users_load_state = useSelector((state: any) => state.users.load_state);
    const all_users = useSelector((state: any) => state.users.all_users);
    const tasks = useSelector((state: any) => state.calendar.all_tasks);
    const role = useSelector((state: any) => state.auth.profile?.role);
    const dispatch = useDispatch<any>();

    if (users_load_state.state === 'not_loaded') {
        dispatch(fetchUsers());
    }

    if (load_state.state === 'not_loaded') {
        dispatch(fetchTasks());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    const onTaskClick = (id: number | string) => {
        let task = tasks.find((e: Task) => e.id === id);

        if (task) {
            const copy = {...task};
            copy.date = new Date(copy.date);
            dispatch(openWithUpdate(copy));
        }
    }

    const onNewClick = (date: number) => {
        dispatch(openWithNew(new Date(date)));
    }

    const createTaskRender = (task: Task) => {
        const owner = users_load_state.state === 'loaded' ? all_users?.find((user: User) => user.id == task.owner_id) : undefined;
        const username = role === 'admin' ? <em style={{opacity: .7}}>({owner ? owner?.username : ''})</em> : null;
        return <Badge key={task.id}
            onClick={() => onTaskClick(task.id as number)} 
            bg=""
            style={{cursor: "pointer", backgroundColor: (owner && role === 'admin' ? uniqolor(owner.username, {lightness: 50}).color : 'black')}}>
            {task.title} 
            {username}
        </Badge>;
    }

    const today = new Date;
    const year = props.year ? props.year : (new Date).getFullYear();
    const month = props.month ? props.month : (new Date).getMonth();
    const month_start = new Date(year, month);
    const month_end = new Date(year, month + 1, 0);
    const start_date = new Date(month_start.getFullYear(), month_start.getMonth(), month_start.getDate());
    const end_date = new Date(month_end.getFullYear(), month_end.getMonth(), month_end.getDate());
    start_date.setDate(month_start.getDay() * -1 + 2);
    end_date.setDate(end_date.getDate() + (7 - month_end.getDay()));

    const cursor_date = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate());

    const rows = [];
    let active_row : any[] = [];
    let row_key = 0;
    let day_key = 0;
    while (cursor_date <= end_date) {
        let classes = ['calendar-day'];
        const time = cursor_date.getTime();

        if (cursor_date < month_start || cursor_date > month_end) classes.push('outside');
        if (cursor_date.getDay() === 6 || cursor_date.getDay() === 0) classes.push('weekend');
        if (isSameDay(today, cursor_date)) classes.push('today');

        active_row.push(<div className={classes.join(' ')} key={day_key} onDoubleClick={() => onNewClick(time)}>
            <div key="day" className="day">
                {(cursor_date < month_start || cursor_date > month_end) ? 
                    format(cursor_date, "d. L eeeeee", { locale: cs }) : 
                    format(cursor_date, "d. eeeeee", { locale: cs })}
                <Button variant="secondary" size="sm" onClick={() => onNewClick(time)}>+</Button>
            </div>
            <div key="tasks" className="tasks">
                {tasks.filter((task: Task) => task.date && (new Date(task.date)).toDateString() === cursor_date.toDateString() ).map((task: Task) => createTaskRender(task))}
            </div>
        </div>)

        if (cursor_date.getDay() == 0) {
            rows.push(<div key={row_key} className="calendar-row">{active_row}</div>);
            row_key++;
            active_row = [];
        }

        cursor_date.setDate(cursor_date.getDate() + 1);
        day_key++;
    }

    return <div>{rows}</div>
}