import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchTasks as apiFetchTasks, updateTask as apiUpdateTask, createTask as apiCreateTask, deleteTask as apiDeleteTask } from './api';


export const fetchTasks = createAsyncThunk(
    'tasks/fetchTasks',
    async (_, { getState, dispatch }) : Promise<Task[]> => {
        const state : any = getState();
        const tasks = await apiFetchTasks(dispatch, state.auth.api_token);
        return tasks;
    }
)

export const updateTask = createAsyncThunk(
    'tasks/updateTask',
    async (task: Task, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiUpdateTask(dispatch, state.auth.api_token, task);
        return task;
    }
)

export const createTask = createAsyncThunk(
    'tasks/createTask',
    async (task: Task, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const id = await apiCreateTask(dispatch, state.auth.api_token, task);
        task.id = id as number;
        return task;
    }
)

export const deleteTask = createAsyncThunk(
    'tasks/deleteTask',
    async (id: number, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        await apiDeleteTask(dispatch, state.auth.api_token, id);
        return id;
    }
)

const tasksSlice = createSlice({
    name: "calendar",
    initialState: {
        all_tasks: [] as Task[],
        load_state: {
            state: 'not_loaded'
        } as LoadState
    },
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(fetchTasks.fulfilled, (state, action) => {
            state.all_tasks = action.payload;
            state.load_state.state = 'loaded';
        });
        builder.addCase(fetchTasks.pending, (state, action) => {
            state.load_state.state = 'loading';
        });
        builder.addCase(fetchTasks.rejected, (state, action) => {
            state.load_state.state = 'error';
            state.load_state.error_message = action.payload as string;
        });
        builder.addCase(updateTask.fulfilled, (state, action) => {
            const index = state.all_tasks.findIndex((e) => {
                return e.id === action.payload.id;
            });
            state.all_tasks[index] = action.payload;
        });
        builder.addCase(createTask.fulfilled, (state, action) => {
            let task = action.payload;
            state.all_tasks.push(task);
        });
        builder.addCase(deleteTask.fulfilled, (state, action) => {
            let id = action.payload;
            let index = state.all_tasks.findIndex(a => a.id === id);
            state.all_tasks.splice(index, 1);
        });
    }
});

export default tasksSlice.reducer