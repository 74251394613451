import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchApiToken as apifetchApiToken, fetchUserProfile as apiFetchUserProfile, expireApiToken, updatePassword as apiUpdatePassword, updateSessionState as apiUpdateSessionState, updateProfile } from './api';
import { createFlashMessageWithDispatch } from '../FlashMessages/utils';

interface Auth {
    username: string,
    password: string
}

interface SessionState {
    load_state: LoadState,
    valid_until?: Date
}

export const fetchApiToken = createAsyncThunk(
    'auth/getToken',
    async (auth: Auth) => {
        let token = await apifetchApiToken(auth.username, auth.password);
        token = token.token;
        sessionStorage.setItem('token', token);
        return token;
    }
)

export const fetchUserProfile = createAsyncThunk(
    'auth/getProfile',
    async (_, { getState, dispatch }) => {
        const state : any = getState();
        const profile = await apiFetchUserProfile(dispatch, state.auth.api_token);
        if (!profile.display_name) {
            profile.display_name = profile.username;
        }
        return profile;
    }
)

export const logOut = createAsyncThunk(
    'auth/logout',
    async (_, { getState }) => {
        const state : any = getState();
        expireApiToken(state.auth.api_token).then(() => {
            sessionStorage.removeItem('token');
            window.location.reload();
        });
    }
)

export const updatePassword = createAsyncThunk(
    'auth/updatePassword',
    async (data: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const response = await apiUpdatePassword(dispatch, state.auth.api_token, data.former_password, data.new_password);
        
        if (response.status === 200) {
            createFlashMessageWithDispatch(dispatch, "success", "Heslo bylo změněno.");
        } else {
            createFlashMessageWithDispatch(dispatch, "danger", "Došlo k chybě při změně hesla. Zkontrolujte, zda se hesla shodují.");
        }
        
        return;
    }
)

export const updateUserProfile = createAsyncThunk(
    'auth/updateUserPassword',
    async (data: any, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const response = await updateProfile(dispatch, state.auth.api_token, data);
        
        if (response.status === 200) {
            createFlashMessageWithDispatch(dispatch, "success", "Profil byl upraven.");
        } else {
            createFlashMessageWithDispatch(dispatch, "danger", "Došlo k chybě při úpravě profilu.");
        }
        
        return;
    }
)

export const updateSessionState = createAsyncThunk(
    'auth/updateSessionState',
    async (_, { getState, dispatch }) : Promise<any> => {
        const state : any = getState();
        const response : any = await apiUpdateSessionState(dispatch, state.auth.api_token);

        if (!response?.valid_until || response.valid_until?.getTime() < (new Date).getTime()) {
            dispatch(logOut());
        }

        return response;
    }
)

const customersSlice = createSlice({
    name: "auth",
    initialState: {
        api_token: sessionStorage.getItem('token') as string | undefined,
        profile: undefined,
        profile_load_state: {state: 'not_loaded'} as LoadState,
        password_update_state: {state: 'loaded'} as LoadState,
        session_state: {load_state: {state: 'not_loaded'}, valid_until: undefined} as SessionState,
        load_state: {
            state: !!sessionStorage.getItem('token') === true ? 'loaded' : 'not_loaded' 
        } as LoadState
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchApiToken.fulfilled, (state, action) => {
            state.api_token = action.payload;
            state.load_state.state = 'loaded';
        });
        builder.addCase(fetchApiToken.pending, (state) => {
            state.load_state.state = 'loading';
        });
        builder.addCase(fetchApiToken.rejected, (state, action) => {
            state.load_state.state = 'error';
            state.load_state.error_message = action.payload as string;
        });
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.profile = action.payload;
            state.profile_load_state.state = 'loaded';
        });
        builder.addCase(fetchUserProfile.pending, (state, action) => {
            state.profile_load_state.state = 'loading';
        });
        builder.addCase(fetchUserProfile.rejected, (state, action) => {
            state.profile_load_state.state = 'error';
            state.profile_load_state.error_message = action.payload as string;
        });
        builder.addCase(logOut.fulfilled, (state) => {
            state.api_token = undefined;
            state.load_state.state = 'not_loaded';
            state.load_state.error_message = undefined;
        });
        builder.addCase(updatePassword.pending, (state) => {
            state.password_update_state.state = 'loading';
        });
        builder.addCase(updatePassword.rejected, (state) => {
            state.password_update_state.state = 'loaded';
        });
        builder.addCase(updatePassword.fulfilled, (state) => {
            state.password_update_state.state = 'loaded';
        });
        builder.addCase(updateUserProfile.pending, (state) => {
            state.profile_load_state.state = 'loading';
        });
        builder.addCase(updateUserProfile.rejected, (state) => {
            state.profile_load_state.state = 'error';
        });
        builder.addCase(updateUserProfile.fulfilled, (state, action) => {
            state.profile = action.meta.arg;
            state.profile_load_state.state = 'loaded';
        });
        builder.addCase(updateSessionState.pending, (state) => {
            state.session_state.load_state.state = 'loading';
        });
        builder.addCase(updateSessionState.rejected, (state) => {
            state.session_state.load_state.state = 'error';
        });
        builder.addCase(updateSessionState.fulfilled, (state, action) => {
            state.session_state.load_state.state = 'loaded';
            state.session_state.valid_until = action.payload.valid_until;
        });
    }
});

export default customersSlice.reducer