import { performApiRequest } from '../Common/api'

export const fetchUsers = async (dispatch: any, token: string) => {
    const response = await performApiRequest(dispatch, '/users', 'GET', token);
    const body = await response.json();
    for (let user of body) {
        user.password_change_load_state = {state: 'loaded'};
    }
    return body;
};

export const updateUser = async (dispatch: any, token: string, user: User) => {
    await performApiRequest(dispatch, '/users/' + user.id, 'PUT', token, user);
};

export const createUser = async (dispatch: any, token: string, user: User) : Promise<number> => {
    const response = await performApiRequest(dispatch, '/users', 'POST', token, user);
    const id = parseInt(response.headers.get('Id') as string);
    return id;
};

export const deleteUser = async (dispatch: any, token: string, id: number) => {
    await performApiRequest(dispatch, '/users/' + id, 'DELETE', token);
};

export const updateuserPassword = async (dispatch: any, token: string, id: number | string, password: string) => {
    await performApiRequest(dispatch, '/users/update-password/' + id, 'POST', token, {new_password: password});
};