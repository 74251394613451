import states from '../../Common/saleStates';

interface Props {
    state?: string
}

const SaleState = (props: Props) => {
    if (props.state === null || props.state === undefined || props.state === '') {
        return <div></div>;
    }

    return <div className="sale-state-display" style={{color: states[props.state as keyof typeof states].color}}>
        <div className="sale-state-display-color" style={{backgroundColor: states[props.state as keyof typeof states].color}}></div>
        {states[props.state as keyof typeof states].name}
    </div>
}

export default SaleState;