import { useForm, SubmitHandler } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Inputs = {
    display_name: string
};

type Props = {
    profile: User,
    onUserFormSuccess: Function
}

export default function UserForm(props: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
        defaultValues: props.profile
    });

    const onSubmit: SubmitHandler<Inputs> = data => {
        props.onUserFormSuccess(data);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Login</Form.Label>
                        <Form.Control type="text" disabled={true} value={props.profile.username} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Celé jméno</Form.Label>
                        <Form.Control type="text" {...register("display_name", {maxLength: 200})} isInvalid={!!errors.display_name} />
                        <Form.Text className={errors.display_name ? "text-danger" : "text-success"}>{errors.display_name?.message}&nbsp;</Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Button className="float-end" variant="success" type="submit">Uložit</Button>
        </Form>
    );
}