import { useCallback, useState, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Datagrid, { Order } from '../Common/Datagrid';
import { Button, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { fetchSales } from '../../Sale/salesSlice';
import states from '../../Common/saleStates';
import SaleState from '../Common/SaleState';

type Props = {
    customer?: Customer,
    hide_state_filter?: boolean
}

interface DatagridModel {
    id?: number | string,
    state?: ReactElement,
    __state_code?: string,
    customer: string,
    date?: Date,
    subject?: string,
    date_last_changed?: Date
}

export default function SalesDatagrid(props: Props) {
    const navigate = useNavigate();
    const handleRowClick = useCallback((row: DatagridModel) => navigate('/sale/' + row.id + (props.customer ? ("/" + props.customer.id) : "")), []);
    const [state_filter, setStateFilter] = useState([] as string[]);
    const handleStateFilterClick = (code: keyof typeof states) => {
        const index = state_filter.indexOf(code);
        if (index !== -1) {
            state_filter.splice(index, 1);
        } else {
            state_filter.push(code);
        }
        setStateFilter([...state_filter]);
    };
    
    const load_state = useSelector((state: any) => state.sales.load_state);
    const sales = useSelector((state: any) => props.customer ? state.sales.all_sales.filter((e: Sale) => e.customer_id === props.customer?.id) : state.sales.all_sales) as Sale[];
    const customers = useSelector((state: any) => state.customers.all_customers);
    const dispatch = useDispatch<any>();

    if (load_state.state === 'not_loaded') {
        dispatch(fetchSales());
        return <div></div>;
    } else if(load_state.state === 'loading') {
        return <div className="text-center"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>;
    } else if (load_state.state === 'error') {
        return <Alert variant="danger">{load_state.error_message}</Alert>
    }

    let rows = sales.map((sale) => {
        const customer = customers.find((e: Customer) => e.id === sale.customer_id);
        let customer_name = customer ? customer.name : '';
        if (customer && customer.contact_person) {
            customer_name = customer_name + " (" + customer.contact_person + ")";
        }

        return {
            id: sale.id,
            __state_code: sale.state,
            state: <SaleState state={sale.state} />,
            customer: customer_name,
            date: sale.date,
            subject: sale.subject,
            date_last_changed: sale.date_modified ? sale.date_modified : sale.date_created
        }
    });

    if (state_filter.length > 0) {
        rows = rows.filter((row) => state_filter.includes(row.__state_code as string));
    }

    const headers = {
        'id':  {title: "#", style: { width: "100px" }},
        'state': {title: "Status"},
        'customer': {title: "Zákazník"},
        'date': {title: "Datum"},
        'subject': {title: "Věc"},
        'date_last_changed': {title: "Datum poslední změny"}
    };

    const default_order = {
        column: 'date_last_changed',
        direction: -1
    } as Order;

    let filters : ReactElement | undefined = undefined;
    if (!props.hide_state_filter) {
        const state_filters = [];
        for (const code in states) {
            let class_name = 'sale-state-filter-block';
            class_name = state_filter.includes(code) ? (class_name + " active") : class_name;
            state_filters.push(<div key={code} className={class_name} onClick={() => handleStateFilterClick(code as keyof typeof states)}>
                <div key="color" className="sale-state-filter-color" style={{backgroundColor: states[code as keyof typeof states].color}}></div>
                <div key="text" className="sale-state-filter-text">{states[code as keyof typeof states].name}</div>
            </div>);
        }

        const state_filters_wrapped = [];
        while (state_filters.length > 0) {
            state_filters_wrapped.push(<Col key={state_filters.length}>{state_filters.splice(0, 3)}</Col>);
        }
        filters = <Row>{state_filters_wrapped}</Row>;
    }

    return (
        <div>
            <Button className="float-end mb-3" variant="primary" onClick={() => navigate('/sale/new' + (props.customer ? ("/" + props.customer.id) : ""))}>Nový obchod</Button>
            {filters}
            <br />
            <Datagrid data={rows} headers={headers} onRowClick={handleRowClick} default_order={default_order} />
        </div>
    );
}