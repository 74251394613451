import { Table, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux'
import { uploadAttachment, deleteAttachment } from '../../Customer/customersSlice';
import { uploadAttachment as uploadAttachmentSale, deleteAttachment as deleteAttachmentSale } from '../../Sale/salesSlice';
import { downloadAttachment } from '../../Common/attachmentsApi';
import { useForm } from 'react-hook-form';
import FileDownload from 'js-file-download';
import { useEffect } from 'react';

type Props = {
    parent: Customer | Sale,
    attachments?: Attachment[],
    readOnly?: boolean
}

export default function Attachments(props: Props) {
    const { register, handleSubmit, reset, watch, setError, clearErrors, formState: { errors } } = useForm();
    const token = useSelector((state: any) => state.auth.api_token);
    const dispatch = useDispatch<any>();
    const attachments = props.attachments || [];
    const files = watch("file");

    const isCustomer = (parent: Customer | Sale) : parent is Customer => {
        if ((parent as Sale).subject) return false;
        return true;
    }

    const onSubmit = (data: any) => {

        if (data.file === undefined) {
            return;
        }

        const form_data = new FormData();
        for (const file of data.file) {
            form_data.append('file[]', file);
        }
        if (isCustomer(props.parent)) {
            dispatch(uploadAttachment({customer_id: props.parent.id, form_data: form_data}));
        } else {
            dispatch(uploadAttachmentSale({sale_id: props.parent.id, form_data: form_data}));
        }
        reset();
    }

    let file_size_ok = true;
    if (files !== undefined && files.length > 0) {
        for (let file of files) {
            if (file.size > 1024 * 1024 * 20) {
                file_size_ok = false;
                break;
            }
        }
    }

    if (!file_size_ok && !errors.file) {
        setError('file', {type: 'filesize', message: 'Jeden nebo více souborů přesahuje maximální velikost 20MiB, soubory nelze nahrát.'});
    }

    if (file_size_ok && errors.file) {
        clearErrors();
    }

    if (file_size_ok && files !== undefined && files.length > 0) {
        onSubmit({file: files});
        reset();
    }

    const onDeleteClick = (id: number) => {
        if (isCustomer(props.parent)) {
            dispatch(deleteAttachment({customer_id: props.parent.id, id: id}));
        } else {
            dispatch(deleteAttachmentSale({sale_id: props.parent.id, id: id}));
        }
    }

    const onDownloadClick = (id: number) => {
        const attachment = props.parent.attachments?.find((e: Attachment) => e.id === id);
        downloadAttachment(dispatch, token, id).then((content: any) => {
            FileDownload(content, attachment?.filename as string);
        });
    }

    return <div>
        <hr />
        {!props.readOnly ? 
        <form className="float-end" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
                <Form.Control type="file" multiple={true} {...register("file")} isInvalid={!!errors.file} />
                <Form.Text className={errors.file ? "text-danger" : "text-success"}>{errors.file?.message as string}</Form.Text>
            </Form.Group>
        </form> : null}
        <h2>Přílohy</h2>
        <Table>
            <thead>
                <tr>
                    <th>Typ</th>
                    <th>Název souboru</th>
                    <th style={{width: "30px"}}></th>
                </tr>
            </thead>
            <tbody>
                {attachments.map((row) => {
                    return <tr key={row.id}>
                        <td>{row.type}</td>
                        <td><a href="##" onClick={() => onDownloadClick(row.id as number)}>{row.filename}</a></td>
                        <td>{row.id ? <a onClick={() => {
                            if (window.confirm("Opravdu chcete přílohu smazat? Tato operace je NEVRATNÁ!")) {
                                onDeleteClick(row.id as number);
                            }
                        }}><FontAwesomeIcon icon={faTrash} className="text-danger" style={{cursor: 'pointer'}} /></a> : ''}
                        </td>
                    </tr>
                })}
            </tbody>
        </Table>
    </div>
};