import { Alert, Table } from "react-bootstrap"
import { format } from "date-fns";

type Props = {
    sale: Sale
}

export default function LegacySale(props: Props) {
    const sale = props.sale;
    return <div>
        <Alert color="info">Tento obchod je migrován z původní aplikace a je použito zjednodušené zobrazení. Obchod nelze editovat.</Alert>
        <Table striped bordered size="sm">
            <tr>
                <th>Datum</th>
                <td>{format(sale.date, 'd.M.yyyy')}</td>
            </tr>
            <tr>
                <th>Věc</th>
                <td>{sale.subject}</td>
            </tr>
            <tr>
                <th>Druh financování</th>
                <td>{sale.legacy_data?.description}</td>
            </tr>
            <tr>
                <th>Leasingová společnost</th>
                <td>{sale.legacy_data?.leasing_company}</td>
            </tr>
            <tr>
                <th>Leasingové společnosti</th>
                <td>{sale.legacy_data?.leasing_companies}</td>
            </tr>
            <tr>
                <th>Dovoz</th>
                <td>{sale.legacy_data?.import}</td>
            </tr>
            <tr>
                <th>Úkoly</th>
                <td>{sale.legacy_data?.tasks}</td>
            </tr>
            <tr>
                <th>Poznámky</th>
                <td>{sale.legacy_data?.notes}</td>
            </tr>
            <tr>
                <th>Dodavatel</th>
                <td>{sale.legacy_data?.supplier}</td>
            </tr>
            <tr>
                <th>Datum podpisu</th>
                <td>{sale.legacy_data?.date_signed ? format(sale.legacy_data?.date_signed, 'd.M.yyyy') : null}</td>
            </tr>
            <tr>
                <th>Poznámka k podpisu</th>
                <td>{sale.legacy_data?.signature_note}</td>
            </tr>
            <tr>
                <th>PD</th>
                <td>{sale.legacy_data?.pd}, z pojistky: {sale.legacy_data?.pd_insurance}, z auta: {sale.legacy_data?.pd_car}, cash: {sale.legacy_data?.pd_cash}, náklady: {sale.legacy_data?.pd_expenses}</td>
            </tr>
            <tr>
                <th>Náklady</th>
                <td>{sale.legacy_data?.expense}</td>
            </tr>
            <tr>
                <th>Pojištění</th>
                <td>{sale.legacy_data?.note_1}</td>
            </tr>
            <tr>
                <th>Poznámka 2</th>
                <td>{sale.legacy_data?.note_2}</td>
            </tr>
            <tr>
                <th>Poznámka 3</th>
                <td>{sale.legacy_data?.title}</td>
            </tr>
        </Table>
    </div>
}