import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Auth/authSlice';
import customersReducer from './Customer/customersSlice';
import salesReducer from './Sale/salesSlice';
import flashMessagesReducer from './FlashMessages/flashMessagesSlice';
import usersReducer from './User/usersSlice';
import calendarReducer from './Calendar/calendarSlice';
import tasksModalReducer from './Calendar/tasksModalSlice';
import reportingReducer from './Reporting/reportingSlice';
import leadsRedurect from './Lead/leadsSlice';

const app_reducer = combineReducers({
    auth: authReducer,
    customers: customersReducer,
    sales: salesReducer,
    flashMessages: flashMessagesReducer,
    users: usersReducer,
    calendar: calendarReducer,
    tasksModal: tasksModalReducer,
    reporting: reportingReducer,
    leads: leadsRedurect
});

const root_reducer = (state: any, action: any) => {
    if (action.type === 'global/resetState') {
        return app_reducer(undefined, action);
    }

    return app_reducer(state, action);
}

export default configureStore({
    reducer: root_reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})