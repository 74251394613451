import config from '../config.json';
import { logOut } from '../Auth/authSlice';

export const performApiRequest = async (dispatch: any, endpoint: string, method: string, token: string, payload?: object, payload_type : string = 'json') => {
    const headers = new Headers();
    headers.append('X-API-Key', token);
    const options = {
        method: method,
        headers: headers
    } as any;

    if (payload) {
        if (payload_type === 'json') {
            options.body = JSON.stringify(payload);
        } else {
            options.body = payload;
        }
    }

    const response = await fetch(config.API_URL + endpoint, options);

    if (response.status === 401) {
        dispatch(logOut());
    }

    if (!response.ok) {
        throw "Error";
    }
    
    return response;
}