import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


const flashMessagesSlice = createSlice({
    name: "flashMessages",
    initialState: {
        history: [] as FlashMessage[],
        visible: [] as FlashMessage[],
        invalidated: [] as FlashMessage[],
    },
    reducers: {
        showFlash(state, action) {
            let flash: FlashMessage = action.payload;
            state.visible.push(flash);
        },

        invalidateFlash(state, action) {
            let id = action.payload;
            let index = state.visible.findIndex((a: FlashMessage) => a.id === id);
            state.visible[index].invalid = true;
        },

        removeFlash(state, action) {
            let id = action.payload;
            let index = state.visible.findIndex((a: FlashMessage) => a.id === id);
            state.history.push(state.visible[index]);
            state.visible.splice(index, 1);
        }
    }
});

export const { showFlash, invalidateFlash, removeFlash } = flashMessagesSlice.actions
export default flashMessagesSlice.reducer